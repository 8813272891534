import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import { CrudOperations } from '../util/CrudOperations';

const ProductsReport = () => {
    const navigate = useNavigate();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        checkIfLoggedIn();
    }, []);

    return (<div>
        <PieChart
            data={[
                {
                    color: "#E38627",
                    title: "One",
                    value: 10,
                },
                {
                    color: "#C13C37",
                    title: "Two",
                    value: 15,
                },
                {
                    color: "#6A2135",
                    title: "Three",
                    value: 20,
                },
            ]}
            radius={10}
        />
    </div>);
};

export default ProductsReport;