import { Component } from 'react';
import { Util } from './Util';

export class CrudOperations extends Component {
    // CREATE
    static createNewIncome = async (income) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('incomes/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(income)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.errors;
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static createNewExpense = async (expense) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('expenses/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(expense)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.errors;
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static createNewClient = async (client) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('clients/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(client)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.errors;
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static createNewObligation = async (obligation) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('obligations/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obligation)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.errors;
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static createNewMaterial = async (material) => {
        try {
            const response = await fetch('products/newMaterial', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(material)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static createNewType = async (type) => {
        try {
            const response = await fetch('products/newType', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(type)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static createNewWidth = async (width) => {
        try {
            const response = await fetch('products/newWidth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(width)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static createNewThickness = async (thickness) => {
        try {
            const response = await fetch('products/newThickness', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(thickness)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static addNewProduct = async (product) => {
        try {
            const response = await fetch('products/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(product)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static newProductMovement = async (productMovement) => {
        try {
            const response = await fetch('productMovements/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(productMovement)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static newOrder = async (order) => {
        try {
            const response = await fetch('orders/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(order)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static newInvoice = async (invoice) => {
        try {
            const response = await fetch('invoices/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(invoice)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    // UPDATE
    static updateClient = async (client) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('clients/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(client)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.errors;
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static setObligationAsPaid = async (id) => {
        try {
            const response = await fetch('obligations/setAsPaid', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });

            const data = await response.json();

            if (!data.success) {
                Util.showError(`Неуспешно отбелязване на задължението като платено. Грешка: ${data.error}`);

                return false;
            }

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static setObligationAsUnpaid = async (id) => {
        const result = { success: false, incomeId: null };

        try {
            const response = await fetch('obligations/setAsUnpaid', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });

            const data = await response.json();

            if (!data.success) {
                Util.showError(`Неуспешно отбелязване на задължението като платено. Грешка: ${data.error}`);

                return false;
            }

            result.success = true;
            result.incomeId = data.incomeId;

            return result;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static withdrawProduct = async (product) => {
        try {
            const response = await fetch('products/withdrawProduct', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(product)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static returnProduct = async (product) => {
        try {
            const response = await fetch('products/returnProduct', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(product)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static updateOrder = async (order) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('orders/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(order)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.errors;
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static editMaterial = async (material) => {
        try {
            const response = await fetch('products/editMaterial', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(material)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static editType = async (type) => {
        try {
            const response = await fetch('products/editType', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(type)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static editWidth = async (width) => {
        try {
            const response = await fetch('products/editWidth', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(width)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static editThickness = async (thickness) => {
        try {
            const response = await fetch('products/editThickness', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(thickness)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static editProduct = async (product) => {
        try {
            const response = await fetch('products/editProduct', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(product)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static editProductMovement = async (product) => {
        try {
            const response = await fetch('productMovements/edit', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(product)
            });

            const data = await response.json();

            return data.success;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    // DELETE
    static deleteObligation = async (id) => {
        try {
            const response = await fetch('obligations/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteIncome = async (id) => {
        try {
            const response = await fetch('incomes/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteExpense = async (id) => {
        try {
            const response = await fetch('expenses/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteClient = async (id) => {
        try {
            const response = await fetch('clients/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteMaterial = async (id) => {
        try {
            const response = await fetch('products/deleteMaterial', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteType = async (id) => {
        try {
            const response = await fetch('products/deleteType', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteWidth = async (id) => {
        try {
            const response = await fetch('products/deleteWidth', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteThickness = async (id) => {
        try {
            const response = await fetch('products/deleteThickness', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteProduct = async (id) => {
        try {
            const response = await fetch('products/deleteProduct', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteOrder = async (id) => {
        try {
            const response = await fetch('orders/deleteOrder', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    static deleteInvoice = async (id) => {
        try {
            const response = await fetch('invoices/deleteInvoice', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            });
            const data = await response.json();

            return data.success ? '' : data.error;
        } catch (e) {
            Util.showError(e);

            return e;
        }
    };

    // GET
    static getAllIncomes = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('incomes/all');
            const incomesData = await response.json();

            return incomesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getAllExpenses = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('expenses/all');
            const expensesData = await response.json();

            return expensesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getTotal = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('incomes/total');
            const totalData = await response.json();

            return totalData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getIncomesForThisWeek = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('incomes/currentWeek');
            const incomesData = await response.json();

            return incomesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getExpensesForThisWeek = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('expenses/currentWeek');
            const expensesData = await response.json();

            return expensesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getIncomesForThisMonth = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('incomes/currentMonth');
            const incomesData = await response.json();

            return incomesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getExpensesForThisMonth = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('expenses/currentMonth');
            const expensesData = await response.json();

            return expensesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getMonthlyTotalsForCurrentYear = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('incomes/monthlyTotalsForCurrentYear');
            const data = await response.json();

            return data;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getClients = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('clients/all');
            const clientsData = await response.json();

            return clientsData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getObligations = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('obligations/all');
            const obligationsData = await response.json();

            return obligationsData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getNotPaidObligations = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('obligations/notPaid');
            const obligationsData = await response.json();

            return obligationsData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getMaterials = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('products/materials');
            const materialsData = await response.json();

            return materialsData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getTypes = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('products/types');
            const typesData = await response.json();

            return typesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getWidths = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('products/widths');
            const widthsData = await response.json();

            return widthsData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getThicknesses = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('products/thicknesses');
            const thicknessesData = await response.json();

            return thicknessesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getObligationsForClient = async (id) => {
        try {
            const response = await fetch(`obligations/getForClient?id=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();

            if (!data.success) {
                Util.showError(`Неуспешно взимане на задълженията. Грешка: ${data.error}`);

                return [];
            }

            return data.obligations;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static getProducts = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('products/all');
            const productsData = await response.json();

            return productsData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getProductMovements = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('productMovements/all');
            const productMovementsData = await response.json();

            return productMovementsData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getOrders = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('orders/all');
            const ordersData = await response.json();

            return ordersData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getInvoices = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('invoices/all');
            const invoicesData = await response.json();

            return invoicesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getPaymentTypes = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('invoices/allPaymentTypes');
            const paymentTypesData = await response.json();

            return paymentTypesData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getPaymentsFrom = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('invoices/allPaymentsFrom');
            const paymentsFromData = await response.json();

            return paymentsFromData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    static getFolders = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('folders/all');
            const foldersData = await response.json();

            return foldersData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };

    // AUTHENTICATION
    static login = async (loginInfo) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginInfo)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.errors;
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static isAuthenticated = async () => {
        try {
            const response = await fetch('user/isAuthenticated');
            const result = await response.json();

            return result;
        } catch (e) {
            Util.showError(e);

            return false;
        }
    }

    static logout = async () => {
        const response = await fetch('user/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();

        return data.success;
    };

    static changePassword = async (changePasswordData) => {
        const result = { success: false, errors: null };

        try {
            const response = await fetch('user/changePassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(changePasswordData)
            });

            const data = await response.json();

            if (data.success) {
                result.success = true;

                return result;
            } else {
                const errors = data.map(x => x.errors);
                result.errors = errors;

                return result;
            }
        } catch (e) {
            Util.showError(e);

            return false;
        }
    };

    static getUsers = async () => {
        try {
            const check = await fetch('user/redirectIfNotLogged');
            if (check.redirected) {
                return;
            }

            const response = await fetch('user/all');
            const usersData = await response.json();

            return usersData;
        } catch (e) {
            Util.showError(e);

            return [];
        }
    };
}