import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ReportsDropdown from './ReportsDropdown';
import FoldersDropdown from './FoldersDropdown';
import AdministrationDropdown from './AdministrationDropdown';
import { Util } from '../util/Util';
import { CrudOperations } from '../util/CrudOperations';
import './NavMenu.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            collapsed: true,
            dropdownOpen: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    onLogout = async (event) => {
        event.preventDefault();

        try {
            confirmAlert({
                title: 'Потвърждение за изход от системата',
                message: `Сигурен ли си, че искаш да излезеш?`,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        await CrudOperations.logout();
                        Util.toggleButtonsForNotLoggedUser();

                        const origin = window.location.origin;
                        window.location.replace(`${origin}/login`);
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">UltraFlex</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem style={{ display: 'none' }} id="foldersDropdown" className="m-1">
                                <FoldersDropdown />
                            </NavItem>
                            <NavItem style={{ display: 'none' }} id="reportsDropdown" className="m-1">
                                <ReportsDropdown />
                            </NavItem>
                            <NavItem style={{ display: 'none' }} id="administrationDropdown" className="m-1">
                                <AdministrationDropdown />
                            </NavItem>
                            <NavItem style={{ display: 'none' }} id="logoutButton">
                                <NavLink tag={Link} className="text-dark m-1" to="/asd" onClick={this.onLogout}>Отписване</NavLink>
                            </NavItem>
                            <NavItem style={{ display: 'inline' }} id="loginButton">
                                <NavLink tag={Link} className="text-dark m-1" to="/login">Вход</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
