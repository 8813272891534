import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';
import { Util } from '../util/Util';

const Login = () => {
    const navigate = useNavigate();

    const validatePassword = (password) => {
        if (!password || password.length <= 6) {
            return false;
        }

        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;

        return password.match(regex) ? true : false;
    };

    const onLogin = async (event) => {
        event.preventDefault();

        const form = document.getElementById('loginForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const userName = document.getElementById('userName');
        const password = document.getElementById('password');
        const userNameFeedback = document.getElementById('UserNameFeedback');
        const passwordFeedback = document.getElementById('PasswordFeedback');

        if (!userName.value || userName.value.length < 4) {
            userNameFeedback.setHTML('Потребителското име трябва да бъде поне 4 символа.');
            userNameFeedback.classList.remove('hiddenElement');
            userNameFeedback.classList.add('visibleElement');
            userNameFeedback.previousElementSibling.value = '';
        } else {
            //if (userNameFeedback) {
            //    userNameFeedback.setHTML('');
            //    userNameFeedback.classList.add('hiddenElement');
            //    userNameFeedback.classList.remove('visibleElement');
            //}
        }

        const isValidPassword = validatePassword(password.value || null);

        if (!isValidPassword) {
            passwordFeedback.setHTML('Паролата трябва да съдържа поне 6 символа, включващи главни и малки букви, цифри и знаци.');
            passwordFeedback.classList.remove('hiddenElement');
            passwordFeedback.classList.add('visibleElement');
            passwordFeedback.previousElementSibling.value = '';
        } else {
            //if (passwordFeedback) {
            //    passwordFeedback.setHTML('');
            //    passwordFeedback.classList.add('hiddenElement');
            //    passwordFeedback.classList.remove('visibleElement');
            //}
        }

        const login = {
            userName: userName.value,
            password: password.value
        };

        const result = await CrudOperations.login(login);

        if (result.success) {
            Util.showSuccess(`Влизането като потребител: ${userName.value} успешно!`);

            navigate('/');
        } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            form.classList.add('was-validated');

            keys.forEach(key => {
                const error = errors[key][0];
                const feedbackId = `${key.trim()}Feedback`;
                const feedbackElement = document.getElementById(feedbackId);

                if (feedbackElement) {
                    feedbackElement.setHTML(error);
                    feedbackElement.classList.remove('hiddenElement');
                    feedbackElement.classList.add('visibleElement');
                    feedbackElement.previousElementSibling.value = '';

                    Util.showError(error);
                }
            });

            Util.showError(`Неуспешен вход като потребител: ${userName.value}.`);
            return;
        }
    };

    return (
        <div>
            <h1 className="text-center mb-5">Вход в системата</h1>
            <form className="needs-validation" id="loginForm" noValidate>
                <div className="form-group mb-2">
                    <label htmlFor="userName">Потребител</label>
                    <input type="text" className="form-control" id="userName" placeholder="Въведи потребителското си име" required />
                    <div id="UserNameFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="password">Парола</label>
                    <input type="password" className="form-control" id="password" placeholder="Въведи парола" required />
                    <div id="PasswordFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <button type="submit" className="btn btn-outline-success" onClick={onLogin}>Вход</button>
            </form>
        </div>
    );
}

export default Login;