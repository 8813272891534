import React, { useEffect, useState } from 'react';
import { CrudOperations } from '../util/CrudOperations';
import { Util } from '../util/Util';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%'
    }
};

const Store = (props) => {
    const navigate = useNavigate();

    const [products, setProducts] = useState(null);
    const [usedPales, setUsedPales] = useState(null);
    const [usedRolls, setUsedRolls] = useState(null);
    const [usedKilograms, setUsedKilograms] = useState(null);
    const [usedNotes, setUsedNotes] = useState(null);
    const [productMovements, setProductMovements] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductFullInfo, setSelectedProductFullInfo] = useState(null);

    // Editing
    const [itemForEdit, setItemForEdit] = useState(null);
    const [editedPales, setEditedPales] = useState(null);
    const [editedRolls, setEditedRolls] = useState(null);
    const [editedKilograms, setEditedKilograms] = useState(null);
    const [editedNotes, setEditedNotes] = useState(null);
    const [productMovementForEdit, setProductMovementForEdit] = useState(null);
    const [editedProductMovementPales, setEditedProductMovementPales] = useState(null);
    const [editedProductMovementRolls, setEditedProductMovementRolls] = useState(null);
    const [editedProductMovementKilograms, setEditedProductMovementKilograms] = useState(null);
    const [editedProductMovementNotes, setEditedProductMovementNotes] = useState(null);
    const [editedProductMovementInternalId, setEditedProductMovementInternalId] = useState(null);

    // Modals
    const [newProductModalIsOpen, setNewProductModalIsOpen] = useState(false);
    const [useProductModalIsOpen, setUseProductModalIsOpen] = useState(false);
    const [returnProductModalIsOpen, setReturnProductModalIsOpen] = useState(false);
    const [editProductModalIsOpen, setEditProductModalIsOpen] = useState(false);
    const [editProductMovementModalIsOpen, setEditProductMovementModalIsOpen] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const getProductsRequest = async () => await CrudOperations.getProducts();
    const getProductMovementsRequest = async () => await CrudOperations.getProductMovements();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getProductsData = async () => setProducts(await getProductsRequest());
        const getProductMovementsData = async () => setProductMovements(await getProductMovementsRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getProductsData();
        getProductMovementsData();
        checkIfLoggedIn();
    }, []);

    const openModal = (event) => {
        // "Нова стока", "Теглене", "Връщане"
        const choice = event.currentTarget.innerHTML;

        if (choice === 'Нова стока') {
            setNewProductModalIsOpen(true);
        } else if (choice === 'Теглене') {
            setUseProductModalIsOpen(true);
        } else if (choice === 'Връщане') {
            setReturnProductModalIsOpen(true);
        }
    };

    const closeModal = (event) => {
        if (!event) {
            setNewProductModalIsOpen(false);
            setUseProductModalIsOpen(false);
        }

        // Three options - 'newProductForm', 'useProductForm', 'returnProductForm'
        const modalType = event.currentTarget.parentElement.name;

        if (modalType === 'newProductForm') {
            setNewProductModalIsOpen(false);
        } else if (modalType === 'useProductForm') {
            setUseProductModalIsOpen(false);
        } else if (modalType === 'returnProductForm') {
            setReturnProductModalIsOpen(false);
        }
    };

    const onAddNewProduct = async (event) => {
        event.preventDefault();

        const form = document.getElementById('newProductForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const palesInput = document.getElementById('palesInput');
        const rollsInput = document.getElementById('rollsInput');
        const kilogramsInput = document.getElementById('kilogramsInput');
        const notesInput = document.getElementById('notesInput');

        const palesValue = palesInput.value;
        const rollsValue = rollsInput.value;
        const kilogramsValue = kilogramsInput.value;
        const notesValue = notesInput.value;

        const palesFeedback = document.getElementById('PalesFeedback');
        const rollsFeedback = document.getElementById('RollsFeedback');
        const kilogramsFeedback = document.getElementById('KilogramsFeedback');

        if (!palesValue || palesValue < 0) {
            palesFeedback.setHTML('Стойността на палетата не може да бъде под 0.');
            palesFeedback.classList.remove('hiddenElement');
            palesFeedback.classList.add('visibleElement');
            palesFeedback.previousElementSibling.value = '';
        } else {
            //palesFeedback.setHTML('');
            //palesFeedback.classList.add('hiddenElement');
            //palesFeedback.classList.remove('visibleElement');
        }

        if (!rollsValue || rollsValue < 0) {
            rollsFeedback.setHTML('Стойността на ролки не може да бъде под 0.');
            rollsFeedback.classList.remove('hiddenElement');
            rollsFeedback.classList.add('visibleElement');
            rollsFeedback.previousElementSibling.value = '';
        } else {
            //rollsFeedback.setHTML('');
            //rollsFeedback.classList.add('hiddenElement');
            //rollsFeedback.classList.remove('visibleElement');
        }

        if (!kilogramsValue || kilogramsValue < 0) {
            kilogramsFeedback.setHTML('Стойността на килограмите не може да бъде под 0.');
            kilogramsFeedback.classList.remove('hiddenElement');
            kilogramsFeedback.classList.add('visibleElement');
            kilogramsFeedback.previousElementSibling.value = '';
        } else {
            //kilogramsFeedback.setHTML('');
            //kilogramsFeedback.classList.add('hiddenElement');
            //kilogramsFeedback.classList.remove('visibleElement');
        }

        if (!palesValue || !rollsValue || !kilogramsValue || palesValue < 0 || rollsValue < 0 || kilogramsValue < 0) {
            return;
        }

        const product = {};
        product.materialId = props.materialId;
        product.typeId = props.typeId;
        product.thicknessId = props.thicknessId;
        product.widthId = props.widthId;
        product.pales = palesValue;
        product.rolls = rollsValue;
        product.kilograms = kilogramsValue;
        product.notes = notesValue;

        await CrudOperations.addNewProduct(product);
        setProducts(await getProductsRequest());
        setNewProductModalIsOpen(false);
    };

    const validateValuesForUsedForm = (foundProduct, palesFeedback, rollsFeedback, kilogramsFeedback) => {
        let valid = false;

        if (usedPales > foundProduct.pales) {
            palesFeedback.setHTML(`Не може да се използват повече от наличните ${foundProduct.pales} палета.`);
            palesFeedback.classList.remove('hiddenElement');
            palesFeedback.classList.add('visibleElement');
            palesFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
        } else {
            //palesFeedback.setHTML('');
            //palesFeedback.classList.add('hiddenElement');
            //palesFeedback.classList.remove('visibleElement');

            valid = true;
        }

        if (usedRolls > foundProduct.rolls) {
            rollsFeedback.setHTML(`Не може да се използват повече от наличните ${foundProduct.rolls} ролки.`);
            rollsFeedback.classList.remove('hiddenElement');
            rollsFeedback.classList.add('visibleElement');
            rollsFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
        } else {
            //rollsFeedback.setHTML('');
            //rollsFeedback.classList.add('hiddenElement');
            //rollsFeedback.classList.remove('visibleElement');

            valid = true;
        }

        if (usedKilograms > foundProduct.kilograms) {
            kilogramsFeedback.setHTML(`Не може да се използват повече от наличните ${foundProduct.kilograms} килограма.`);
            kilogramsFeedback.classList.remove('hiddenElement');
            kilogramsFeedback.classList.add('visibleElement');
            kilogramsFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
        } else if (usedKilograms === 0) {
            kilogramsFeedback.setHTML(`Използваните килограми не могат да бъдат 0.`);
            kilogramsFeedback.classList.remove('hiddenElement');
            kilogramsFeedback.classList.add('visibleElement');
            kilogramsFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
        } else {
            //kilogramsFeedback.setHTML('');
            //kilogramsFeedback.classList.add('hiddenElement');
            //kilogramsFeedback.classList.remove('visibleElement');

            valid = true;
        }

        if (usedPales < 0) {
            palesFeedback.setHTML(`Използваните палета не могат да бъдат по-малко от 0.`);
            palesFeedback.classList.remove('hiddenElement');
            palesFeedback.classList.add('visibleElement');
            palesFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
        }

        if (usedRolls < 0) {
            rollsFeedback.setHTML(`Използваните ролки не могат да бъдат по-малко от 0.`);
            rollsFeedback.classList.remove('hiddenElement');
            rollsFeedback.classList.add('visibleElement');
            rollsFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
        }

        if (usedKilograms < 0) {
            kilogramsFeedback.setHTML(`Използваните килограми не могат да бъдат отрицателно число.`);
            kilogramsFeedback.classList.remove('hiddenElement');
            kilogramsFeedback.classList.add('visibleElement');
            kilogramsFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
        }

        return valid;
    };

    const validateValuesForReturnForm = (foundProduct, palesFeedback, rollsFeedback, kilogramsFeedback) => {
        let valid = false;
        let oneFalse = false;

        if (usedPales < 0) {
            palesFeedback.setHTML(`Върнатите палета не могат да бъдат по-малко от 0.`);
            palesFeedback.classList.remove('hiddenElement');
            palesFeedback.classList.add('visibleElement');
            palesFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
            oneFalse = true;
        } else {
            //palesFeedback.setHTML('');
            //palesFeedback.classList.add('hiddenElement');
            //palesFeedback.classList.remove('visibleElement');

            valid = true;
        }

        if (usedRolls < 0) {
            rollsFeedback.setHTML(`Върнатите ролки не могат да бъдат по-малко от 0.`);
            rollsFeedback.classList.remove('hiddenElement');
            rollsFeedback.classList.add('visibleElement');
            rollsFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
            oneFalse = true;
        } else {
            //rollsFeedback.setHTML('');
            //rollsFeedback.classList.add('hiddenElement');
            //rollsFeedback.classList.remove('visibleElement');

            valid = true;
        }

        if (usedKilograms < 0) {
            kilogramsFeedback.setHTML(`Върнатите килограми не могат да бъдат отрицателно число.`);
            kilogramsFeedback.classList.remove('hiddenElement');
            kilogramsFeedback.classList.add('visibleElement');
            kilogramsFeedback.previousElementSibling.childNodes[1].value = '';

            valid = false;
            oneFalse = true;
        } else {
            //kilogramsFeedback.setHTML('');
            //kilogramsFeedback.classList.add('hiddenElement');
            //kilogramsFeedback.classList.remove('visibleElement');

            valid = true;
        }

        if (oneFalse) {
            return false;
        }

        return valid;
    };

    const onUseProduct = async (id) => {
        const form = document.getElementById('useProductForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const foundProduct = products.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];

        const palesFeedback = document.getElementById(`${id}_UsedPalesFeedback`);
        const rollsFeedback = document.getElementById(`${id}_UsedRollsFeedback`);
        const kilogramsFeedback = document.getElementById(`${id}_UsedKilogramsFeedback`);
        const valid = validateValuesForUsedForm(foundProduct, palesFeedback, rollsFeedback, kilogramsFeedback);

        if (!valid) {
            return;
        }

        const product = {};
        product.materialId = props.materialId;
        product.typeId = props.typeId;
        product.thicknessId = props.thicknessId;
        product.widthId = props.widthId;
        product.pales = usedPales || 0;
        product.rolls = usedRolls || 0;
        product.kilograms = usedKilograms;
        product.notes = usedNotes;
        product.id = id;

        const productMovement = {};
        productMovement.materialId = props.materialId;
        productMovement.typeId = props.typeId;
        productMovement.thicknessId = props.thicknessId;
        productMovement.widthId = props.widthId;
        productMovement.pales = usedPales || 0;
        productMovement.rolls = usedRolls || 0;
        productMovement.kilograms = usedKilograms;
        productMovement.notes = usedNotes;
        productMovement.internalId = foundProduct.internalId;
        productMovement.movementType = 'Теглене';

        await CrudOperations.withdrawProduct(product);
        await CrudOperations.newProductMovement(productMovement);

        setProductMovements(await getProductMovementsRequest());
        setProducts(await getProductsRequest());
        setUseProductModalIsOpen(false);
    };

    const onReturnProduct = async (id) => {
        const form = document.getElementById('returnProductForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const foundProduct = products.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];

        const palesFeedback = document.getElementById(`${id}_UsedPalesFeedback`);
        const rollsFeedback = document.getElementById(`${id}_UsedRollsFeedback`);
        const kilogramsFeedback = document.getElementById(`${id}_UsedKilogramsFeedback`);
        const valid = validateValuesForReturnForm(foundProduct, palesFeedback, rollsFeedback, kilogramsFeedback);

        if (!valid) {
            return;
        }

        const product = {};
        product.materialId = props.materialId;
        product.typeId = props.typeId;
        product.thicknessId = props.thicknessId;
        product.widthId = props.widthId;
        product.pales = usedPales || 0;
        product.rolls = usedRolls || 0;
        product.kilograms = usedKilograms;
        product.notes = usedNotes;
        product.id = id;

        const productMovement = {};
        productMovement.materialId = props.materialId;
        productMovement.typeId = props.typeId;
        productMovement.thicknessId = props.thicknessId;
        productMovement.widthId = props.widthId;
        productMovement.pales = usedPales || 0;
        productMovement.rolls = usedRolls || 0;
        productMovement.kilograms = usedKilograms;
        productMovement.notes = usedNotes;
        productMovement.internalId = foundProduct.internalId;
        productMovement.movementType = 'Връщане';

        await CrudOperations.returnProduct(product);
        await CrudOperations.newProductMovement(productMovement);

        setProductMovements(await getProductMovementsRequest());
        setProducts(await getProductsRequest());
        setReturnProductModalIsOpen(false);
    };

    const deleteProduct = async (id) => {
        try {
            confirmAlert({
                title: 'Потвърждение за изтриване',
                message: `Сигурен ли си, че искаш да изтриеш записа от системата?`,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        const error = await CrudOperations.deleteProduct(id);

                        if (error) {
                            Util.showError(`Не е открит запис за продукта. Error message: ${error}`);
                        } else {
                            setProducts(await getProductsRequest());
                            setProductMovements(await getProductMovementsRequest());
                        }
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    };

    const onChange = (event) => {
        const target = event.target.name;
        const value = event.target.value;

        if (target === 'palesInput') {
            setUsedPales(+value);
        }

        if (target === 'rollsInput') {
            setUsedRolls(+value);
        }

        if (target === 'kilogramsInput') {
            setUsedKilograms(+value);
        }

        if (target === 'usedNotesInput') {
            setUsedNotes(value);
        }
    };

    const cleanUpUseProperties = (_) => {
        setUsedPales(0);
        setUsedRolls(0);
        setUsedKilograms(0);
        setUsedNotes('');
    };

    const onSelect = async (id) => {
        if (selectedProduct) {
            cleanSelectedLine(selectedProduct);
            onCleanSelection(id);
        }

        setSelectedProduct(id);
        setSelectedProductFullInfo(products.filter(x => x.id.toLowerCase() === id.toLowerCase())[0]);

        const selectedLine = document.getElementById(`line_${id}`);
        if (selectedLine) {
            selectedLine.style.backgroundColor = 'lightGray';
        }

        const button = document.getElementById(id);
        button.innerHTML = 'Избрано'
        button.disabled = true;

        return;
    };

    const onEdit = (item) => {
        setItemForEdit(item);
        setEditedPales(item.pales);
        setEditedRolls(item.rolls);
        setEditedKilograms(item.kilograms);
        setEditedNotes(item.notes);
        setEditProductModalIsOpen(true);
    };

    const onProductMovementEdit = (item) => {
        setProductMovementForEdit(item);
        setEditedProductMovementPales(item.pales);
        setEditedProductMovementRolls(item.rolls);
        setEditedProductMovementKilograms(item.kilograms);
        setEditedProductMovementNotes(item.notes);
        setEditedProductMovementInternalId(item.internalId);
        setEditProductMovementModalIsOpen(true);
    };

    const onEditProduct = async (event) => {
        event.preventDefault();

        const form = document.getElementById('editProductForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const edited = itemForEdit;
        edited.notes = editedNotes;

        await CrudOperations.editProduct(edited);
        setProducts(await getProductsRequest());
        setEditProductModalIsOpen(false);
    };

    const onEditProductMovement = async (event) => {
        event.preventDefault();

        const form = document.getElementById('editProductMovementForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const edited = productMovementForEdit;
        edited.notes = editedProductMovementNotes;

        await CrudOperations.editProductMovement(edited);
        setProductMovements(await getProductMovementsRequest());
        setEditProductMovementModalIsOpen(false);
    };

    const cleanSelectedLine = (selectLineId) => {
        const selectedLine = document.getElementById(`line_${selectLineId}`);
        if (selectedLine) {
            selectedLine.style.backgroundColor = '';
        }
    };

    const onCleanSelection = (id) => {
        cleanSelectedLine(id);

        const button = document.getElementById(selectedProduct);
        if (button) {
            button.innerHTML = 'Избери';
            button.disabled = false;
        }

        setSelectedProduct(null);
        setSelectedProductFullInfo(null);
    };

    const generateRows = (products) => {
        if (!products) {
            return '';
        }

        const onlyPales = products.map(x => x.pales) || [];
        const onlyRolls = products.map(x => x.rolls) || [];
        const onlyKilograms = products.map(x => x.kilograms) || [];

        const movementsForThisProductOnly = productMovements.filter(x => x.materialId.toLowerCase() === props.materialId.toLowerCase()
            && x.typeId.toLowerCase() === props.typeId.toLowerCase()
            && x.thicknessId.toLowerCase() === props.thicknessId.toLowerCase()
            && x.widthId.toLowerCase() === props.widthId.toLowerCase());

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Последна промяна</th>
                    <th scope="col">Вътрешен номер</th>
                    <th scope="col">Палета</th>
                    <th scope="col">Ролки</th>
                    <th scope="col">Килограми</th>
                    <th scope="col">Бележки</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {products.map(x => {
                    return (<tr key={x.id} id={`line_${x.id}`}>
                        <th scope="row">{new Date(x.lastModified).toLocaleString("bg-BG")}</th>
                        <td>{x.internalId}</td>
                        <td>{x.pales}</td>
                        <td>{x.rolls}</td>
                        <td>{x.kilograms}</td>
                        <td>{x.notes}</td>
                        <td><button className="btn btn-sm btn-outline-primary" onClick={() => onSelect(x.id)} id={x.id}>Избери</button></td>
                        <td><button className="btn btn-sm btn-outline-secondary" onClick={() => onEdit(x)} id={x.id}>Редактирай</button></td>
                        <Modal isOpen={editProductModalIsOpen} onRequestClose={() => setEditProductModalIsOpen(false)} style={customStyles} contentLabel="Редактирай стока">
                            <h2 className="text-center mb-3">Редактирай стока</h2>
                            <form className="needs-validation" id="editProductForm" noValidate name="editProductForm">
                                <div className="row">
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За материал:
                                            <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={props.forMaterial} disabled />
                                        </label>
                                        <div id="ForMaterialFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За тип:
                                            <input className="form-control" id="forTypeInput" name="forType" type="text" value={props.forType} disabled />
                                        </label>
                                        <div id="ForTypeFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За дебелина:
                                            <input className="form-control" id="forThicknessInput" name="forThickness" type="text" value={props.forThickness} disabled />
                                        </label>
                                        <div id="ForWidthFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За широчина:
                                            <input className="form-control" id="forWidth" name="forWidth" type="text" value={props.forWidth} disabled />
                                        </label>
                                        <div id="WidthFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Палета:
                                            <input className="form-control" id="palesInput" name="palesInput" type="number" step=".01" value={editedPales} disabled />
                                        </label>
                                        <div id="PalesFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Ролки:
                                            <input className="form-control" id="rollsInput" name="rollsInput" type="number" step=".01" value={editedRolls} disabled />
                                        </label>
                                        <div id="RollsFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Нето кг:
                                            <input className="form-control" id="kilogramsInput" name="kilogramsInput" type="number" step=".01" value={editedKilograms} disabled/>
                                        </label>
                                        <div id="KilogramsFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Бележки:
                                            <textarea className="form-control" id="notesInput" value={editedNotes} onChange={ev => setEditedNotes(ev.target.value)}></textarea>
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={() => setEditProductModalIsOpen(false)}>Затвори</button>
                                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onEditProduct}>Редактирай</button>
                            </form>
                        </Modal>
                        <td><button className="btn btn-sm btn-outline-danger" onClick={() => deleteProduct(x.id)} type="submit">Изтриване</button></td>
                    </tr>);
                })}
                <tr className="table-active">
                    <th scope="row">Тотал</th>
                    <td></td>
                    <td>{onlyPales.reduce((x, y) => x + y, 0).toLocaleString()}</td>
                    <td>{onlyRolls.reduce((x, y) => x + y, 0).toLocaleString()}</td>
                    <td>{onlyKilograms.reduce((x, y) => x + y, 0)}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>);
    };

    const generateArchiveInfo = (data) => {
        if (!selectedProduct) {
            return;
        }

        const filtered = data.filter(x => x.materialId.toLowerCase() === props.materialId.toLowerCase()
            && x.typeId.toLowerCase() === props.typeId.toLowerCase()
            && x.thicknessId.toLowerCase() === props.thicknessId.toLowerCase()
            && x.widthId.toLowerCase() === props.widthId.toLowerCase()
            && x.internalId === selectedProductFullInfo.internalId);
        filtered.sort((x, y) => new Date(y.dateCreated) - new Date(x.dateCreated));

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Дата</th>
                    <th scope="col">Вътрешен номер</th>
                    <th scope="col">Палета</th>
                    <th scope="col">Ролки</th>
                    <th scope="col">Килограми</th>
                    <th scope="col">Тип</th>
                    <th scope="col">Бележки</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {filtered.map(x => {
                    const movementTypeClass = x.movementType === 'Теглене' ? 'text-danger' : 'text-success';

                    return (<tr key={x.id} id={`line_${x.id}`}>
                        <th scope="row">{new Date(x.dateCreated).toLocaleString("bg-BG")}</th>
                        <td>{x.internalId}</td>
                        <td>{x.pales}</td>
                        <td>{x.rolls}</td>
                        <td>{x.kilograms}</td>
                        <td className={movementTypeClass}>{x.movementType}</td>
                        <td>{x.notes}</td>
                        <td><button className="btn btn-sm btn-outline-secondary" onClick={() => onProductMovementEdit(x)}>Редактирай</button></td>
                        <Modal isOpen={editProductMovementModalIsOpen} onRequestClose={() => setEditProductMovementModalIsOpen(false)} style={customStyles} contentLabel="Редактирай движение на стока">
                            <h2 className="text-center mb-3">Редактирай движение на стока с вътрешен номер {editedProductMovementInternalId}</h2>
                            <form className="needs-validation" id="editProductMovementForm" noValidate name="editProductMovementForm">
                                <div className="row">
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За материал:
                                            <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={props.forMaterial} disabled />
                                        </label>
                                        <div id="ForMaterialFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За тип:
                                            <input className="form-control" id="forTypeInput" name="forType" type="text" value={props.forType} disabled />
                                        </label>
                                        <div id="ForTypeFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За дебелина:
                                            <input className="form-control" id="forThicknessInput" name="forThickness" type="text" value={props.forThickness} disabled />
                                        </label>
                                        <div id="ForWidthFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За широчина:
                                            <input className="form-control" id="forWidth" name="forWidth" type="text" value={props.forWidth} disabled />
                                        </label>
                                        <div id="WidthFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Палета:
                                            <input className="form-control" id="palesInput" name="palesInput" type="number" step=".01" value={editedProductMovementPales} disabled />
                                        </label>
                                        <div id="PalesFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Ролки:
                                            <input className="form-control" id="rollsInput" name="rollsInput" type="number" step=".01" value={editedProductMovementRolls} disabled />
                                        </label>
                                        <div id="RollsFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Нето кг:
                                            <input className="form-control" id="kilogramsInput" name="kilogramsInput" type="number" step=".01" value={editedProductMovementKilograms} disabled />
                                        </label>
                                        <div id="KilogramsFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Бележки:
                                            <textarea className="form-control" id="notesInput" value={editedProductMovementNotes} onChange={ev => setEditedProductMovementNotes(ev.target.value)}></textarea>
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={() => setEditProductMovementModalIsOpen(false)}>Затвори</button>
                                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onEditProductMovement}>Редактирай</button>
                            </form>
                        </Modal>

                    </tr>);
                })}
            </tbody>
        </table>);
    };

    const generateStoreInformation = () => {
        const allSelected = props.materialId && props.typeId && props.thicknessId && props.widthId;

        if (!allSelected || !products) {
            return '';
        }

        if (selectedProductFullInfo
            && (selectedProductFullInfo.materialId.toLowerCase() !== props.materialId.toLowerCase()
                || selectedProductFullInfo.typeId.toLowerCase() !== props.typeId.toLowerCase()
                || selectedProductFullInfo.thicknessId.toLowerCase() !== props.thicknessId.toLowerCase()
                || selectedProductFullInfo.widthId.toLowerCase() !== props.widthId.toLowerCase())) {
            setSelectedProduct(null);
            setSelectedProductFullInfo(null);
        }

        const data = products.filter(x => x.materialId.toLowerCase() === props.materialId.toLowerCase()
            && x.typeId.toLowerCase() === props.typeId.toLowerCase()
            && x.thicknessId.toLowerCase() === props.thicknessId.toLowerCase()
            && x.widthId.toLowerCase() === props.widthId.toLowerCase());

        const disabledStateClass = selectedProduct ? '' : 'disabled';

        return (<div><div className="row mb-5">
            <div className="col-md-12">
                <h2 className="mb-4 text-center">Наличност в складa</h2>
                <span className="text-center">{props.title}</span>
                <button className="btn m-2 btn-outline-secondary" style={{ float: 'left' }} onClick={() => onCleanSelection(selectedProduct)}>Изчисти селекцията</button>
                <button className="btn m-2 btn-outline-primary" style={{ float: 'right' }} onClick={openModal} disabled={disabledStateClass}>Връщане</button>
                <Modal isOpen={returnProductModalIsOpen} onAfterOpen={cleanUpUseProperties} onRequestClose={closeModal} style={customStyles} contentLabel="Връщане на стока">
                    <h2 className="text-center mb-3">Връщане на стока</h2>
                    <form className="needs-validation" id="returnProductForm" noValidate name="returnProductForm">
                        <div className="row">
                            <p>Избери колко искаш да върнеш обратно в този запис:</p>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Палета:
                                    <input className="form-control" onChange={onChange} name="palesInput" type="number" step=".01" />
                                </label>
                                <div id={`${selectedProduct || ''}_UsedPalesFeedback`} className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Ролки:
                                    <input className="form-control" onChange={onChange} name="rollsInput" type="number" step=".01" />
                                </label>
                                <div id={`${selectedProduct || ''}_UsedRollsFeedback`} className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Нето кг:
                                    <input className="form-control" onChange={onChange} name="kilogramsInput" type="number" step=".01" />
                                </label>
                                <div id={`${selectedProduct || ''}_UsedKilogramsFeedback`} className="invalid-feedback hiddenElement"></div>
                            </div>
                        </div>
                        <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                        <button className="btn btn-outline-primary" style={{ float: 'right' }} type="button" onClick={() => onReturnProduct(selectedProductFullInfo ? selectedProductFullInfo.id : null)}>Върни стока</button>
                    </form>
                </Modal>
                <button className="btn m-2 btn-outline-primary" style={{ float: 'right' }} onClick={openModal} disabled={disabledStateClass}>Теглене</button>
                <Modal isOpen={useProductModalIsOpen} onAfterOpen={cleanUpUseProperties} onRequestClose={closeModal} style={customStyles} contentLabel="Теглене на стока">
                    <h2 className="text-center mb-3">Теглене на стока</h2>
                    <form className="needs-validation" id="useProductForm" noValidate name="useProductForm">
                        <div className="row">
                            <p>Избери колко искаш да изтеглиш от този запис, налични: {selectedProductFullInfo ? selectedProductFullInfo.pales : ''} палета, {selectedProductFullInfo ? selectedProductFullInfo.rolls : ''} ролки, {selectedProductFullInfo ? selectedProductFullInfo.kilograms : ''} килограма.</p>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Палета:
                                    <input className="form-control" onChange={onChange} name="palesInput" type="number" step=".01" />
                                </label>
                                <div id={`${selectedProduct || ''}_UsedPalesFeedback`} className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Ролки:
                                    <input className="form-control" onChange={onChange} name="rollsInput" type="number" step=".01" />
                                </label>
                                <div id={`${selectedProduct || ''}_UsedRollsFeedback`} className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Нето кг:
                                    <input className="form-control" onChange={onChange} name="kilogramsInput" type="number" step=".01" />
                                </label>
                                <div id={`${selectedProduct || ''}_UsedKilogramsFeedback`} className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Бележки:
                                    <textarea className="form-control" name="usedNotesInput" id="usedNotesInput" onChange={onChange}></textarea>
                                </label>
                            </div>
                        </div>
                        <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                        <button className="btn btn-outline-primary" style={{ float: 'right' }} type="button" onClick={() => onUseProduct(selectedProductFullInfo ? selectedProductFullInfo.id : null)}>Изтегли стока</button>
                    </form>
                </Modal>
                <button className="btn m-2 btn-outline-primary" style={{ float: 'right' }} onClick={openModal}>Нова стока</button>
                <Modal isOpen={newProductModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нова стока">
                    <h2 className="text-center mb-3">Добави нова стока</h2>
                    <form className="needs-validation" id="newProductForm" noValidate name="newProductForm">
                        <div className="row">
                            <div className="mb-3 col-md-3">
                                <label className="form-label">За материал:
                                    <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={props.forMaterial} disabled />
                                </label>
                                <div id="ForMaterialFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">За тип:
                                    <input className="form-control" id="forTypeInput" name="forType" type="text" value={props.forType} disabled />
                                </label>
                                <div id="ForTypeFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">За дебелина:
                                    <input className="form-control" id="forThicknessInput" name="forThickness" type="text" value={props.forThickness} disabled />
                                </label>
                                <div id="ForWidthFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">За широчина:
                                    <input className="form-control" id="forWidth" name="forWidth" type="text" value={props.forWidth} disabled />
                                </label>
                                <div id="WidthFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Палета:
                                    <input className="form-control" id="palesInput" name="palesInput" type="number" step=".01" />
                                </label>
                                <div id="PalesFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Ролки:
                                    <input className="form-control" id="rollsInput" name="rollsInput" type="number" step=".01" />
                                </label>
                                <div id="RollsFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Нето кг:
                                    <input className="form-control" id="kilogramsInput" name="kilogramsInput" type="number" step=".01" />
                                </label>
                                <div id="KilogramsFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Бележки:
                                    <textarea className="form-control" id="notesInput"></textarea>
                                </label>
                            </div>
                        </div>
                        <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                        <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onAddNewProduct}>Създай</button>
                    </form>
                </Modal>
                {generateRows(data)}
            </div>
        </div>
            <div className="row mb-5">
                <div className="col-md-12">
                    <h2 className="mb-4 text-center">Архив на теглене</h2>
                    {generateArchiveInfo(productMovements)}
                </div>
            </div>
        </div>);
    };

    const storeInformation = generateStoreInformation();

    return (<div>
        {storeInformation}
    </div>);
}

export default Store;