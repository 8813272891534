import IncomeCalculator from "./incomesAndExpenses/IncomeCalculator";
import IncomesReport from "./incomesAndExpenses/IncomesReport";
import Details from "./incomesAndExpenses/Details";
import Products from "./products/Products";
import ProductsReport from "./products/ProductsReport";
import Clients from "./clients/Clients";
import ClientsReport from "./clients/ClientsReport";
import ClientInfo from "./clients/ClientInfo";
import Orders from "./orders/Orders";
import Invoices from "./invoices/Invoices";
import Login from "./authentication/Login";
import ChangePassword from "./authentication/ChangePassword";
import Users from "./authentication/Users";
import Home from "./components/Home";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/incomes',
        element: <IncomeCalculator />
    },
    {
        path: '/incomes-report',
        element: <IncomesReport />
    },
    {
        path: '/products',
        element: <Products />
    },
    {
        path: '/products-report',
        element: <ProductsReport />
    },
    {
        path: '/clients',
        element: <Clients />
    },
    {
        path: '/clients-report',
        element: <ClientsReport />
    },
    {
        path: '/clientInfo',
        element: <ClientInfo />
    },
    {
        path: '/details',
        element: <Details />
    },
    {
        path: '/orders',
        element: <Orders />
    },
    {
        path: '/invoices',
        element: <Invoices />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/change-password',
        element: <ChangePassword />
    },
    {
        path: '/users',
        element: <Users />
    }
];

export default AppRoutes;
