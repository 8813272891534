import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Util } from '../util/Util';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '55%',
        height: '390px'
    }
};

const Invoices = () => {
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [paymentsFrom, setPaymentsFrom] = useState(null);
    const [customDate, setCustomDate] = useState(new Date());
    const [editItem, setEditItem] = useState(null);
    const [newInvoiceModalIsOpen, setNewInvoiceModalIsOpen] = useState(false);
    const [editInvoiceModalIsOpen, setEditInvoiceModalIsOpen] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const getInvoicesRequest = async () => await CrudOperations.getInvoices();
    const getPaymentTypesRequest = async () => await CrudOperations.getPaymentTypes();
    const getPaymentsFromRequest = async () => await CrudOperations.getPaymentsFrom();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getInvoicesData = async () => setInvoices(await getInvoicesRequest());
        const getPaymentTypesData = async () => setPaymentTypes(await getPaymentTypesRequest());
        const getPaymentsFromData = async () => setPaymentsFrom(await getPaymentsFromRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getInvoicesData();
        getPaymentTypesData();
        getPaymentsFromData();
        checkIfLoggedIn();
    }, []);

    const openModal = (event) => {
        // "Добави нова", "Редактиране"
        const choice = event.currentTarget.innerHTML;

        if (choice === 'Добави нова') {
            setNewInvoiceModalIsOpen(true);
        } else if (choice === 'Редактиране') {
            setEditInvoiceModalIsOpen(true);
        }
    };

    const closeModal = (event) => {
        if (!event) {
            setNewInvoiceModalIsOpen(false);
            setEditInvoiceModalIsOpen(false);
        }

        // Two options - 'newOrderForm', 'editInvoiceForm'
        const modalType = event.currentTarget.parentElement.name;

        if (modalType === 'newInvoiceForm') {
            setNewInvoiceModalIsOpen(false);
        } else if (modalType === 'editInvoiceForm') {
            setEditInvoiceModalIsOpen(false);
        }
    };

    const populatePickers = () => {
        if (!paymentTypes || !paymentsFrom) {
            return;
        }

        const paymentTypePicker = document.getElementById('paymentTypePicker');
        const paymentFromPicker = document.getElementById('paymentFromPicker');

        if (!paymentTypePicker || !paymentFromPicker) {
            return;
        }

        paymentTypes.forEach(x => {
            const item = document.createElement("option");
            const itemText = document.createTextNode(x.name);

            item.appendChild(itemText);
            paymentTypePicker.appendChild(item);
        });

        paymentsFrom.forEach(x => {
            const item = document.createElement("option");
            const itemText = document.createTextNode(x.from);

            if (x.from !== 'BG') {
                item.appendChild(itemText);
                paymentFromPicker.appendChild(item);
            }
        });
    };

    const onAddNewInvoice = async (event) => {
        event.preventDefault();

        const form = document.getElementById('newInvoiceForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const paymentTypeInput = document.getElementById('paymentTypePicker');
        const invoiceNumberInput = document.getElementById('invoiceNumberInput');
        const sellerInput = document.getElementById('sellerInput');
        const paymentFromInput = document.getElementById('paymentFromPicker');
        const sumInput = document.getElementById('sumInput');

        const paymentTypeDefaultValue = 'Избери тип на плащане';
        const paymentTypeValue = paymentTypeInput.value;
        const paymentFromValue = paymentFromInput.value;
        const invoiceNumberValue = invoiceNumberInput.value;
        const sellerValue = sellerInput.value;
        const sumValue = sumInput.value;

        const paymentTypeFeedback = document.getElementById('PaymentTypeFeedback');
        const invoiceNumberFeedback = document.getElementById('InvoiceNumberFeedback');
        const sellerFeedback = document.getElementById('SellerFeedback');
        const sumFeedback = document.getElementById('SumFeedback');

        if (paymentTypeValue === paymentTypeDefaultValue) {
            paymentTypeFeedback.setHTML('Изборът на тип на плащане е задължително.');
            paymentTypeFeedback.classList.remove('hiddenElement');
            paymentTypeFeedback.classList.add('visibleElement');
        } else {
            //paymentTypeFeedback.setHTML('');
            //paymentTypeFeedback.classList.add('hiddenElement');
            //paymentTypeFeedback.classList.remove('visibleElement');
        }

        if (!invoiceNumberValue || invoiceNumberValue.length === 0) {
            invoiceNumberFeedback.setHTML('Номер на фактура е задължителен.');
            invoiceNumberFeedback.classList.remove('hiddenElement');
            invoiceNumberFeedback.classList.add('visibleElement');
        } else {
            //invoiceNumberFeedback.setHTML('');
            //invoiceNumberFeedback.classList.add('hiddenElement');
            //invoiceNumberFeedback.classList.remove('visibleElement');
        }

        if (!sellerValue || sellerValue.length === 0) {
            sellerFeedback.setHTML('Доставчик е задължителен.');
            sellerFeedback.classList.remove('hiddenElement');
            sellerFeedback.classList.add('visibleElement');
        } else {
            //sellerFeedback.setHTML('');
            //sellerFeedback.classList.add('hiddenElement');
            //sellerFeedback.classList.remove('visibleElement');
        }

        if (!sumValue || sumValue.length === 0) {
            sumFeedback.setHTML('Сумата на фактурата е задължителна.');
            sumFeedback.classList.remove('hiddenElement');
            sumFeedback.classList.add('visibleElement');
        } else {
            //sumFeedback.setHTML('');
            //sumFeedback.classList.add('hiddenElement');
            //sumFeedback.classList.remove('visibleElement');
        }

        const isEU = paymentFromValue === 'EU';
        const taxBaseInputValue = sumValue / 1.2;
        const taxInputValue = sumValue - taxBaseInputValue;

        const invoice = {};
        invoice.paymentType = paymentTypes.filter(x => x.name === paymentTypeValue)[0].id;
        invoice.invoiceNumber = invoiceNumberValue;
        invoice.seller = sellerValue;
        invoice.paymentFrom = paymentsFrom.filter(x => x.from === paymentFromValue)[0].id;
        invoice.sum = sumValue;
        invoice.tax = isEU ? 0 : parseFloat(taxInputValue).toFixed(2);//0.2 * sumValue;
        invoice.taxBase = isEU ? sumValue : parseFloat(taxBaseInputValue).toFixed(2);//0.8 * sumValue;

        await CrudOperations.newInvoice(invoice);
        setInvoices(await getInvoicesRequest());
        setNewInvoiceModalIsOpen(false);
    };

    const deleteInvoice = async (id) => {
        try {
            confirmAlert({
                title: 'Потвърждение за изтриване',
                message: `Сигурен ли си, че искаш да изтриеш тази фактура от системата?`,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        const error = await CrudOperations.deleteInvoice(id);

                        if (error) {
                            Util.showError(`Не е открит запис за фактурата. Error message: ${error}`);
                        } else {
                            setInvoices(await getInvoicesRequest());
                        }
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    };

    const generateRows = (data) => {
        if (!data) {
            return '';
        }

        const filtered = data.filter(x => new Date(x.dateCreated).getFullYear() === customDate.getFullYear() && new Date(x.dateCreated).getMonth() === customDate.getMonth());
        filtered.sort((x, y) => new Date(y.dateCreated) - new Date(x.dateCreated));

        return (<table className="table table-hover mb-5">
            <thead>
                <tr>
                    <th scope="col">Дата</th>
                    <th scope="col">Тип плащане</th>
                    <th scope="col">№ фактура</th>
                    <th scope="col">Доставчик</th>
                    <th scope="col">Място на плащане</th>
                    <th scope="col">Обща сума</th>
                    <th scope="col">ДДС</th>
                    <th scope="col">Данъчна основа</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {filtered.map(x => {
                    const paymentType = paymentTypes ? paymentTypes.filter(pt => pt.id.toLowerCase() === x.paymentType.toLowerCase())[0].name : '';
                    const paymentFrom = paymentsFrom ? paymentsFrom.filter(pf => pf.id.toLowerCase() === x.paymentFrom.toLowerCase())[0].from : '';

                    return (<tr key={x.id}>
                        <th scope="row">{new Date(x.dateCreated).toLocaleString("bg-BG")}</th>
                        <td>{paymentType}</td>
                        <td>{x.invoiceNumber}</td>
                        <td>{x.seller}</td>
                        <td>{paymentFrom}</td>
                        <td>{x.sum}</td>
                        <td>{x.tax}</td>
                        <td>{x.taxBase}</td>
                        <td><button className="btn btn-sm btn-outline-danger" onClick={() => deleteInvoice(x.id)}>Изтриване</button></td>
                    </tr>);
                })}
                <tr className="table-active">
                    <th scope="row">Тотал</th>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{filtered.map(x => x.sum).reduce((x, y) => x + y, 0).toLocaleString()}</td>
                    <td>{filtered.map(x => x.tax).reduce((x, y) => x + y, 0).toLocaleString()}</td>
                    <td>{filtered.map(x => x.taxBase).reduce((x, y) => x + y, 0).toLocaleString()}</td>
                    <td>-</td>
                </tr>
            </tbody>
        </table>);
    };

    const onChange = (event) => {
        event.preventDefault();

        const trigger = event.target.name;
        const value = event.target.value;
        const taxInput = document.getElementById('taxInput');
        const taxBaseInput = document.getElementById('taxBaseInput');
        const sumInput = document.getElementById('sumInput');

        if (trigger === 'paymentFromPicker') {
            const sumValue = sumInput.value;

            if (value === 'BG') {
                sumInput.disabled = false;
                taxBaseInput.disabled = true;

                const taxBaseInputValue = sumValue / 1.2;
                const taxInputValue = sumValue - taxBaseInputValue;

                taxInput.value = parseFloat(taxInputValue).toFixed(2);
                taxBaseInput.value = parseFloat(taxBaseInputValue).toFixed(2);
            } else if (value === 'Non EU') {
                const recalculatedSumValue = sumValue * 1.2;
                const recalculatedTaxValue = recalculatedSumValue - sumValue;

                taxBaseInput.value = parseFloat(sumValue).toFixed(2);
                sumInput.value = parseFloat(recalculatedSumValue).toFixed(2);
                taxInput.value = parseFloat(recalculatedTaxValue).toFixed(2);

                sumInput.disabled = true;
                taxBaseInput.disabled = false;
            } else {
                sumInput.disabled = false;
                taxBaseInput.disabled = true;

                taxInput.value = 0;
                taxBaseInput.value = sumValue;
            }
        } else if (trigger === 'sum') {
            const paymentFromInput = document.getElementById('paymentFromPicker');
            const paymentFromValue = paymentFromInput.value;

            if (paymentFromValue === 'BG') {
                const taxBaseInputValue = value / 1.2;
                const taxInputValue = value - taxBaseInputValue;

                taxInput.value = parseFloat(taxInputValue).toFixed(2);
                taxBaseInput.value = parseFloat(taxBaseInputValue).toFixed(2);
            } else {
                taxInput.value = 0;
                taxBaseInput.value = value;
            }
        } else if (trigger === 'taxBase') {
            const sumInputValue = value * 1.2;
            const taxInputValue = sumInputValue - value;

            sumInput.value = parseFloat(sumInputValue).toFixed(2);
            taxInput.value = parseFloat(taxInputValue).toFixed(2);
        }
    };

    const generateTitle = () => <h3 className="text-center mb-3">Фактури за месец {Util.getMonthFromIndex(customDate.getMonth())}, {customDate.getFullYear()} година</h3>;

    const tableData = generateRows(invoices);
    const title = generateTitle();

    return (<div>
        <h1 className="text-center mb-5">Заприхождаване на фактури</h1>
        <button className="btn btn-md btn-outline-primary" onClick={openModal}>Добави нова</button>
        <Modal isOpen={newInvoiceModalIsOpen} onAfterOpen={populatePickers} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нова фактура">
            <h2 className="text-center mb-3">Добави нова фактура</h2>
            <form className="needs-validation" id="newInvoiceForm" noValidate name="newInvoiceForm">
                <div className="row">
                    <div className="mb-3 col-md-3">
                        <label className="form-label">Тип плащане:
                            <select id="paymentTypePicker" name="paymentTypePicker" className="form-select">
                                <option defaultValue>Избери тип на плащане</option>
                            </select>
                        </label>
                        <div id="PaymentTypeFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label className="form-label">№ фактура:
                            <input className="form-control" id="invoiceNumberInput" name="invoiceNumber" type="text" required />
                        </label>
                        <div id="InvoiceNumberFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label className="form-label">Доставчик:
                            <input className="form-control" id="sellerInput" name="seller" type="text" required />
                        </label>
                        <div id="SellerFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label className="form-label">Място на плащане:
                            <select id="paymentFromPicker" name="paymentFromPicker" className="form-select" onChange={onChange}>
                                <option defaultValue>BG</option>
                            </select>
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Сума:
                            <input className="form-control" id="sumInput" name="sum" type="number" step=".01" onChange={onChange} required />
                        </label>
                        <div id="SumFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">ДДС:
                            <input className="form-control" id="taxInput" name="tax" type="number" step=".01" disabled />
                        </label>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Данъчна основа:
                            <input className="form-control" id="taxBaseInput" name="taxBase" type="number" step=".01" onChange={onChange} disabled />
                        </label>
                    </div>
                </div>
                <p className="hiddenElement">test</p>
                <p className="hiddenElement">test</p>
                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onAddNewInvoice}>Създай</button>
            </form>
        </Modal>
        <span style={{ float: 'right' }}>
            <label className="form-check-label">Избор на конкретен месец и година</label>
            <DatePicker id="customDatePicker" selected={customDate} dateFormat="MM/yyyy" onChange={date => setCustomDate(date)} showMonthYearPicker onKeyDown={(e) => { e.preventDefault(); }} />
        </span>
        <hr />
        <div className="row">
            {title}
            <div className="col-sm-12">
                {tableData}
            </div>
        </div>
    </div>);
};

export default Invoices;