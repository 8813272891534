import React, { useEffect, useState } from 'react';
import { CrudOperations } from '../util/CrudOperations';
import { useNavigate } from 'react-router-dom';

const ClientsReport = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState(null);
    const [obligations, setObligations] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const getClientsRequest = async () => await CrudOperations.getClients();
    const getObligationsRequest = async () => await CrudOperations.getNotPaidObligations();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getClientsAndObligationsData = async () => {
            setClients(await getClientsRequest());
            setObligations(await getObligationsRequest());
        };
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getClientsAndObligationsData();
        checkIfLoggedIn();
    }, []);

    const openClient = (clientId) => {
        const clientData = clients.filter(x => x.id === clientId);

        navigate('/clientInfo', { state: { clientData: clientData[0], cameFrom: '/clients-report' } });
    };

    const generateTableForClientsWithNotPaidObligations = (clients, obligations) => {
        if (obligations.length === 0) {
            return (<p>Няма неплатени задължения.</p>);
        }

        const mappedClientsAndCount = {};
        obligations.forEach(x => {
            if (mappedClientsAndCount[x.clientId]) {
                mappedClientsAndCount[x.clientId] += 1;
            } else {
                mappedClientsAndCount[x.clientId] = 1;
            }
        });

        let i = 0;
        const data = [];
        const keys = Object.keys(mappedClientsAndCount);
        keys.forEach(key => {
            const client = clients.filter(x => x.id === key)[0];

            data.push({ clientName: client.name, clientId: client.id, countOfNotPaidObligations: mappedClientsAndCount[key] });
        });

        const sorted = data.sort((x, y) => y.countOfNotPaidObligations - x.countOfNotPaidObligations);
        const firstTen = sorted.slice(0, 10);

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Брой неплатени задължения</th>
                    <th scope="col">Клиент</th>
                    <th scope="col">Общ дълг</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {firstTen.map(x => {
                    i++;

                    const notPaidObligationsForThisClient = obligations.filter(o => o.clientId === x.clientId).map(o => o.ammount);
                    const sum = notPaidObligationsForThisClient.reduce((a, b) => a + b);

                    return (<tr key={i}>
                        <th className="text-danger">{x.countOfNotPaidObligations}</th>
                        <td>{x.clientName}</td>
                        <td>{sum}</td>
                        <td><button onClick={() => openClient(x.clientId)} className="btn btn-outline-secondary">Детайли</button></td>
                    </tr>);
                })}
            </tbody>
        </table>);
    }

    const generateTableForMostExpensiveNotPaidObligations = (clients, obligations) => {
        if (obligations.length === 0) {
            return (<p>Няма неплатени задължения.</p>);
        }

        const sorted = obligations.sort((a, b) => parseFloat(b.ammount) - parseFloat(a.ammount));
        const biggestTen = sorted.slice(0, 10);

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Дата</th>
                    <th scope="col">Клиент</th>
                    <th scope="col">№ фактура </th>
                    <th scope="col">№ стокова</th>
                    <th scope="col">Партиден №</th>
                    <th scope="col">Сума</th>
                    <th scope="col">Описание стока</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {biggestTen.map(x => {
                    const clientForThisObligation = clients.filter(c => c.id === x.clientId)[0];

                    return (<tr key={x.id}>
                        <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                        <td>{clientForThisObligation.name}</td>
                        <td>{x.invoiceNumber}</td>
                        <td>{x.stockNumber}</td>
                        <td>{x.batchNumber}</td>
                        <td>{x.ammount.toLocaleString()}</td>
                        <td>{x.description}</td>
                        <td><button onClick={() => openClient(x.clientId)} className="btn btn-outline-secondary">Детайли</button></td>
                    </tr>);
                })}
            </tbody>
        </table>);
    };

    const generateTableForObligationsOlderThanMonth = (clients, obligations) => {
        if (obligations.length === 0) {
            return (<p>Няма неплатени задължения.</p>);
        }

        const olderThanMonth = [];
        const now = new Date();
        obligations.forEach(x => {
            const diffInMs = now - new Date(x.date)
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

            if (diffInDays >= 31) {
                olderThanMonth.push(x);
            }
        });

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Дата</th>
                    <th scope="col">Клиент</th>
                    <th scope="col">№ фактура </th>
                    <th scope="col">№ стокова</th>
                    <th scope="col">Партиден №</th>
                    <th scope="col">Сума</th>
                    <th scope="col">Описание стока</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {olderThanMonth.map(x => {
                    const clientForThisObligation = clients.filter(c => c.id === x.clientId)[0];

                    return (<tr key={x.id}>
                        <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                        <td>{clientForThisObligation.name}</td>
                        <td>{x.invoiceNumber}</td>
                        <td>{x.stockNumber}</td>
                        <td>{x.batchNumber}</td>
                        <td>{x.ammount.toLocaleString()}</td>
                        <td>{x.description}</td>
                        <td><button onClick={() => openClient(x.clientId)} className="btn btn-outline-secondary">Детайли</button></td>
                    </tr>);
                })}
            </tbody>
        </table>);
    };

    const generateInformationTable = () => {
        if (!clients || !obligations) {
            return 'Генериране на информация за клиенти и задължения...';
        }

        return (<div>
            <h1 className="text-center title">Справки за клиенти</h1>
            <h3 className="text-center title">Неплатени фактури от повече от 1 месец</h3>
            {generateTableForObligationsOlderThanMonth(clients, obligations)}
            <h3 className="text-center title">Клиенти с най-много неплатени фактури</h3>
            {generateTableForClientsWithNotPaidObligations(clients, obligations)}
            <h3 className="text-center title">Най-скъпи неплатени фактури</h3>
            {generateTableForMostExpensiveNotPaidObligations(clients, obligations)}
        </div>);
    };

    const clientsReport = generateInformationTable();

    return (<div className="container">
        {clientsReport}
    </div>);
};

export default ClientsReport;