import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';

const IncomesReport = () => {
    const navigate = useNavigate();

    const [incomesForThisWeek, setIncomesForThisWeek] = useState(null);
    const [expensesForThisWeek, setExpensesForThisWeek] = useState(null);
    const [incomesForThisMonth, setIncomesForThisMonth] = useState(null);
    const [expensesForThisMonth, setExpensesForThisMonth] = useState(null);
    const [monthlyTotalsForCurrentYear, setMonthlyTotalsForCurrentYear] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const getIncomesForThisWeekRequest = async () => await CrudOperations.getIncomesForThisWeek();
    const getExpensesForThisWeekRequest = async () => await CrudOperations.getExpensesForThisWeek();
    const getIncomesForThisMonthRequest = async () => await CrudOperations.getIncomesForThisMonth();
    const getExpensesForThisMonthRequest = async () => await CrudOperations.getExpensesForThisMonth();
    const getMonthlyTotalsForCurrentYearRequest = async () => await CrudOperations.getMonthlyTotalsForCurrentYear();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getIncomesData = async () => {
            setIncomesForThisWeek(await getIncomesForThisWeekRequest());
            setExpensesForThisWeek(await getExpensesForThisWeekRequest());
            setIncomesForThisMonth(await getIncomesForThisMonthRequest());
            setExpensesForThisMonth(await getExpensesForThisMonthRequest());
            setMonthlyTotalsForCurrentYear(await getMonthlyTotalsForCurrentYearRequest());
        };
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getIncomesData();
        checkIfLoggedIn();
    }, []);

    const generateTableForIncomes = (incomes, expenses) => {
        if (!incomes || !expenses || (incomes.length === 0 && expenses.length === 0)) {
            return;
        }

        const onlyFoilExpenses = expenses.filter(x => x.foil > 0).map(x => -x.foil);
        const onlyMachinesExpenses = expenses.filter(x => x.machines > 0).map(x => -x.machines);
        const onlyRentExpenses = expenses.filter(x => x.rent > 0).map(x => -x.rent);
        const onlyExpensesExpenses = expenses.filter(x => x.expenses > 0).map(x => -x.expenses);
        const allExpenses = [...onlyFoilExpenses, ...onlyMachinesExpenses, ...onlyRentExpenses, ...onlyExpensesExpenses];

        const onlyInitialAmmount = incomes.map(x => x.initialAmmount).concat(allExpenses);
        const onlyFoil = incomes.map(x => x.foil).concat(onlyFoilExpenses);
        const onlyMachines = incomes.map(x => x.machines).concat(onlyMachinesExpenses);
        const onlyRent = incomes.map(x => x.rent).concat(onlyRentExpenses);
        const onlyExpenses = incomes.map(x => x.expenses).concat(onlyExpensesExpenses);

        const incomesAndExpenses = incomes.concat(expenses);
        incomesAndExpenses.sort((x, y) => new Date(y.date) - new Date(x.date));

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Дата</th>
                    <th scope="col">Клиент</th>
                    <th scope="col">№ документ</th>
                    <th scope="col">Тип</th>
                    <th scope="col">Входна сума</th>
                    <th scope="col">Фолио</th>
                    <th scope="col">Машини</th>
                    <th scope="col">Наем</th>
                    <th scope="col">Разходи</th>
                </tr>
            </thead>
            <tbody>
                {incomesAndExpenses.map(x => {
                    if (x.initialAmmount) {
                        return (<tr key={x.id}>
                            <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                            <td>{x.clientName}</td>
                            <td>{x.documentNumber}</td>
                            <td><p className="text-success">Приход</p></td>
                            <td>{x.initialAmmount.toLocaleString()}</td>
                            <td>{x.foil.toLocaleString()}</td>
                            <td>{x.machines.toLocaleString()}</td>
                            <td>{x.rent.toLocaleString()}</td>
                            <td>{x.expenses.toLocaleString()}</td>
                        </tr>);
                    } else {
                        return (<tr key={x.id}>
                            <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                            <td>-</td>
                            <td>-</td>
                            <td><p className="text-danger">Разход</p></td>
                            <td>-</td>
                            <td>{x.foil.toLocaleString() === '0' ? '0' : '-' + x.foil.toLocaleString()}</td>
                            <td>{x.machines.toLocaleString() === '0' ? '0' : '-' + x.machines.toLocaleString()}</td>
                            <td>{x.rent.toLocaleString() === '0' ? '0' : '-' + x.rent.toLocaleString()}</td>
                            <td>{x.expenses.toLocaleString() === '0' ? '0' : '-' + x.expenses.toLocaleString()}</td>
                        </tr>);
                    }
                })}
                <tr className="table-active">
                    <th scope="row">Краен резултат</th>
                    <td>-</td>
                    <td>-</td>
                    <td>Тотал</td>
                    <td>{onlyInitialAmmount.reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{onlyFoil.reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{onlyMachines.reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{onlyRent.reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{onlyExpenses.reduce((x, y) => x + y).toLocaleString()}</td>
                </tr>
            </tbody>
        </table>);
    };

    const generateMonthlyTotalForCurrentYear = (totalsData) => {
        let i = 0;

        if (!totalsData || totalsData.length === 0) {
            return 'Генериране на тотали за месеци...';
        }

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Месец</th>
                    <th scope="col">Входна сума</th>
                    <th scope="col">Фолио</th>
                    <th scope="col">Машини</th>
                    <th scope="col">Наем</th>
                    <th scope="col">Разходи/остатък</th>
                </tr>
            </thead>
            <tbody>
                {totalsData.map(x => {
                    i++;

                    return (<tr key={i}>
                        <th scope="row">{x.month}</th>
                        <td>{x.initialAmmount.toLocaleString()}</td>
                        <td>{x.foil.toLocaleString()}</td>
                        <td>{x.machines.toLocaleString()}</td>
                        <td>{x.rent.toLocaleString()}</td>
                        <td>{x.expenses.toLocaleString()}</td>
                    </tr>);
                })}
                <tr className="table-active">
                    <th scope="row">Краен резултат</th>
                    <td>{totalsData.map(x => x.initialAmmount).reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{totalsData.map(x => x.foil).reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{totalsData.map(x => x.machines).reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{totalsData.map(x => x.rent).reduce((x, y) => x + y).toLocaleString()}</td>
                    <td>{totalsData.map(x => x.expenses).reduce((x, y) => x + y).toLocaleString()}</td>
                </tr>
            </tbody>
        </table>);
    };

    const generateIncomesTable = () => {
        if (!incomesForThisWeek || !incomesForThisMonth) {
            return 'Генериране на информация за приходите...';
        }

        return (<div>
            <h1 className="text-center title">Справки за приходите</h1>
            <h3 className="text-center title">Отчет за седмицата</h3>
            {generateTableForIncomes(incomesForThisWeek, expensesForThisWeek)}
            <h3 className="text-center title">Отчет за месеца</h3>
            {generateTableForIncomes(incomesForThisMonth, expensesForThisMonth)}
            <h3 className="text-center title">Отчет на годината по месеци</h3>
            {generateMonthlyTotalForCurrentYear(monthlyTotalsForCurrentYear)}
        </div>);
    };

    const incomesReport = generateIncomesTable();

    return (<div className="container">
        {incomesReport}
    </div>);
};

export default IncomesReport;