import { Component } from 'react';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export class Util extends Component {
    static showError = (err) => {
        alertify.error(err);
    }

    static showSuccess = (message) => {
        alertify.success(message);
    }

    static showMessage = (message) => {
        alertify.warning(message);
    }

    static getMonthFromIndex = (index) => {
        const mappedMonths = {
            0: 'Януари',
            1: 'Февруари',
            2: 'Март',
            3: 'Април',
            4: 'Май',
            5: 'Юни',
            6: 'Юли',
            7: 'Август',
            8: 'Септември',
            9: 'Октомври',
            10: 'Ноември',
            11: 'Декември',
        };

        return mappedMonths[index];
    }

    static toggleButtonsForLoggedUser = () => {
        const logoutButton = document.getElementById('logoutButton');
        const loginButton = document.getElementById('loginButton');
        const foldersDropdown = document.getElementById('foldersDropdown');
        const reportsDropdown = document.getElementById('reportsDropdown');
        const administrationDropdown = document.getElementById('administrationDropdown');

        if (logoutButton) {
            logoutButton.style.display = 'inline';
        }

        if (foldersDropdown) {
            foldersDropdown.style.display = 'inline';
        }

        if (reportsDropdown) {
            reportsDropdown.style.display = 'inline';
        }

        if (administrationDropdown) {
            administrationDropdown.style.display = 'inline';
        }

        if (loginButton) {
            loginButton.style.display = 'none';
        }
    }

    static toggleButtonsForNotLoggedUser = () => {
        const logoutButton = document.getElementById('logoutButton');
        const loginButton = document.getElementById('loginButton');
        const foldersDropdown = document.getElementById('foldersDropdown');
        const reportsDropdown = document.getElementById('reportsDropdown');
        const administrationDropdown = document.getElementById('administrationDropdown');

        if (logoutButton) {
            logoutButton.style.display = 'none';
        }

        if (foldersDropdown) {
            foldersDropdown.style.display = 'none';
        }

        if (reportsDropdown) {
            reportsDropdown.style.display = 'none';
        }

        if (administrationDropdown) {
            administrationDropdown.style.display = 'none';
        }

        if (loginButton) {
            loginButton.style.display = 'inline';
        }
    }

    static recalculatePalesAccordingToMovements = (productMovements, item) => {
        const internalId = item.internalId;
        const pales = item.pales;

        const movementsForThisItem = productMovements.filter(x => x.internalId === internalId);
        const used = movementsForThisItem.filter(x => x.movementType === 'Теглене');
        const usedPales = used.map(x => x.pales) || [];
        const usedPalesSum = usedPales.reduce((x, y) => x + y, 0);

        const returned = movementsForThisItem.filter(x => x.movementType === 'Връщане');
        const returnedPales = returned.map(x => x.pales) || [];
        const returnedPalesSum = returnedPales.reduce((x, y) => x + y, 0);

        const finalResult = pales - usedPalesSum + returnedPalesSum;

        return finalResult;
    };

    static recalculateRollsAccordingToMovements = (productMovements, item) => {
        const internalId = item.internalId;
        const rolls = item.rolls;

        const movementsForThisItem = productMovements.filter(x => x.internalId === internalId);
        const used = movementsForThisItem.filter(x => x.movementType === 'Теглене');
        const usedRolls = used.map(x => x.rolls) || [];
        const usedRollsSum = usedRolls.reduce((x, y) => x + y, 0);

        const returned = movementsForThisItem.filter(x => x.movementType === 'Връщане');
        const returnedRolls = returned.map(x => x.rolls) || [];
        const returnedRollsSum = returnedRolls.reduce((x, y) => x + y, 0);

        const finalResult = rolls - usedRollsSum + returnedRollsSum;

        return finalResult;
    };

    static recalculateKilogramsAccordingToMovements = (productMovements, item) => {
        const internalId = item.internalId;
        const kilograms = item.kilograms;

        const movementsForThisItem = productMovements.filter(x => x.internalId === internalId);
        const used = movementsForThisItem.filter(x => x.movementType === 'Теглене');
        const usedKilograms = used.map(x => x.kilograms) || [];
        const usedKilogramsSum = usedKilograms.reduce((x, y) => x + y, 0);

        const returned = movementsForThisItem.filter(x => x.movementType === 'Връщане');
        const returnedKilograms = returned.map(x => x.kilograms) || [];
        const returnedKilogramsSum = returnedKilograms.reduce((x, y) => x + y, 0);

        const finalResult = kilograms - usedKilogramsSum + returnedKilogramsSum;

        return finalResult;
    };

    static recalculateTotalPalesAccordingToMovements = (productMovements, initialTotalPales) => {
        const used = productMovements.filter(x => x.movementType === 'Теглене');
        const usedPales = used.map(x => x.pales) || [];
        const usedPalesSum = usedPales.reduce((x, y) => x + y, 0);

        const returned = productMovements.filter(x => x.movementType === 'Връщане');
        const returnedPales = returned.map(x => x.pales) || [];
        const returnedPalesSum = returnedPales.reduce((x, y) => x + y, 0);

        if (!initialTotalPales || initialTotalPales === 0 || initialTotalPales === '0') {
            return 0;
        }

        const finalResult = initialTotalPales - usedPalesSum + returnedPalesSum;

        return finalResult;
    };

    static recalculateTotalRollsAccordingToMovements = (productMovements, initialTotalRolls) => {
        const used = productMovements.filter(x => x.movementType === 'Теглене');
        const usedRolls = used.map(x => x.rolls) || [];
        const usedRollsSum = usedRolls.reduce((x, y) => x + y, 0);

        const returned = productMovements.filter(x => x.movementType === 'Връщане');
        const returnedRolls = returned.map(x => x.rolls) || [];
        const returnedRollsSum = returnedRolls.reduce((x, y) => x + y, 0);

        if (!initialTotalRolls || initialTotalRolls === 0 || initialTotalRolls === '0') {
            return 0;
        }

        const finalResult = initialTotalRolls - usedRollsSum + returnedRollsSum;

        return finalResult;
    };

    static recalculateTotalKilogramsAccordingToMovements = (productMovements, initialTotalKilograms) => {
        const used = productMovements.filter(x => x.movementType === 'Теглене');
        const usedKilograms = used.map(x => x.kilograms) || [];
        const usedKilogramsSum = usedKilograms.reduce((x, y) => x + y, 0);

        const returned = productMovements.filter(x => x.movementType === 'Връщане');
        const returnedKilograms = returned.map(x => x.kilograms) || [];
        const returnedKilogramsSum = returnedKilograms.reduce((x, y) => x + y, 0);

        if (!initialTotalKilograms || initialTotalKilograms === 0) {
            return 0;
        }

        const finalResult = initialTotalKilograms - usedKilogramsSum + returnedKilogramsSum;

        return finalResult;
    };
}