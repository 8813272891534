import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';
import { Util } from '../util/Util';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Clients = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState(null);
    const [searchClients, setSearchClients] = useState(null);
    const [obligations, setObligations] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const getClientsRequest = async () => await CrudOperations.getClients();
    const getObligationsRequest = async () => await CrudOperations.getObligations();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getClientsData = async () => setClients(await getClientsRequest());
        const getObligationsData = async () => setObligations(await getObligationsRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getClientsData();
        getObligationsData();
        checkIfLoggedIn();
    }, []);

    const onClear = (event) => {
        if (event) {
            event.preventDefault();
        }

        const clientNameField = document.getElementById('clientName');
        const bulstatField = document.getElementById('bulstat');
        const addressField = document.getElementById('address');
        const responsiblePersonField = document.getElementById('responsiblePerson');
        const emailField = document.getElementById('email');
        const phoneNumberField = document.getElementById('phoneNumber');
        const form = document.getElementById('createNewClientForm');
        const validationMessages = document.getElementsByClassName('visibleElement');

        clientNameField.value = '';
        bulstatField.value = '';
        addressField.value = '';
        responsiblePersonField.value = '';
        emailField.value = '';
        phoneNumberField.value = '';
        form.classList.remove('was-validated');

        for (let i = 0; i < validationMessages.length; i++) {
            validationMessages[i].classList.remove('visibleElement');
            validationMessages[i].classList.add('hiddenElement');
        }

        return;
    };

    const onCreateNew = async (event) => {
        event.preventDefault();

        const clientNameField = document.getElementById('clientName');
        const bulstatField = document.getElementById('bulstat');
        const addressField = document.getElementById('address');
        const responsiblePersonField = document.getElementById('responsiblePerson');
        const emailField = document.getElementById('email');
        const phoneNumberField = document.getElementById('phoneNumber');

        const clientNameValue = clientNameField.value;
        const bulstatValue = bulstatField.value;
        const addressValue = addressField.value;
        const responsiblePersonValue = responsiblePersonField.value;
        const emailValue = emailField.value;
        const phoneNumberValue = phoneNumberField.value;

        const form = document.getElementById('createNewClientForm');
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');

            return;
        }

        const clientsWithThisName = clients.filter(x => x.name.toLowerCase() === clientNameValue.toLowerCase());
        if (clientsWithThisName.length > 0) {
            event.stopPropagation();
            clientNameField.value = '';
            form.classList.add('was-validated');

            Util.showError(`Клиент с име ${clientNameValue} вече съществува.`);
            return;
        }

        const newClient = {
            name: clientNameValue,
            bulstat: bulstatValue,
            address: addressValue,
            responsiblePerson: responsiblePersonValue,
            email: emailValue,
            phoneNumber: phoneNumberValue
        };

        const result = await CrudOperations.createNewClient(newClient);

        if (result.success) {
            const clientsResult = await getClientsRequest();

            setClients(clientsResult);
        } else {
            event.stopPropagation();

            const errors = result.errors;
            const keys = Object.keys(errors);
            form.classList.add('was-validated');

            keys.forEach(key => {
                const error = errors[key][0];
                const feedbackId = `${key.trim()}Feedback`;
                const feedbackElement = document.getElementById(feedbackId);

                if (feedbackElement) {
                    feedbackElement.setHTML(error);
                    feedbackElement.classList.remove('hiddenElement');
                    feedbackElement.classList.add('visibleElement');
                    feedbackElement.previousElementSibling.value = '';

                    Util.showError(error);
                }
            });

            Util.showError(`Неуспешно записване на нов клиент: ${newClient.name}.`);
            return;
        }

        onClear();
    };

    const openClient = (clientId) => {
        const clientData = clients.filter(x => x.id === clientId);

        navigate('/clientInfo', { state: { clientData: clientData[0], cameFrom: '/clients' } });
    };

    const generateBadge = (clientId) => {
        if (!obligations) {
            return 'Търси се информация за фактури чакащи плащане...';
        }

        const obligationsForClient = obligations.filter(x => x.clientId === clientId && !x.paid);

        if (obligationsForClient.length === 0) {
            return <span className="badge badge-primary badgeCounterZero badge-pill" data-toggle="tooltip" data-placement="top" title="Няма фактури за тази фирма със статус чакане на плащане">0</span>
        } else if (obligationsForClient.length === 1) {
            return <span className="badge badge-primary badgeCounter badge-pill" data-toggle="tooltip" data-placement="top" title="1 фактура за тази фирма е със статус чакане на плащане">1</span>
        } else {
            const titleText = `${obligationsForClient.length} фактури за тази фирма са със статус чакане на плащане`;

            return <span className="badge badge-primary badgeCounter badge-pill" data-toggle="tooltip" data-placement="top" title={titleText}>{obligationsForClient.length}</span>
        }
    }

    const onDelete = async (id) => {
        const selectedClient = clients.filter(x => x.id === id)[0];
        const clientName = selectedClient.name;

        try {
            confirmAlert({
                title: 'Потвърждение за изтриване',
                message: `Сигурен ли си, че искаш да изтриеш клиент ${clientName}?`,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        const error = await CrudOperations.deleteClient(id);

                        if (error) {
                            Util.showError(`Не е открит запис за този клиент. Error message: ${error}`);
                        } else {
                            const clientsData = await getClientsRequest();

                            setClients(clientsData);
                        }
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    };

    const generateClientsRows = (clients) => {
        if (!clients) {
            return 'Генериране на клиенти...';
        }

        if (clients.length === 0) {
            return 'Все още няма добавени клиенти';
        }

        let sorted = [];
        const searchBar = getSearchBar();

        if (searchClients) {
            sorted = searchClients.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            sorted = clients.sort((a, b) => a.name.localeCompare(b.name));
        }

        return (<div>
            <h2 className="mb-4">Всички налични клиенти</h2>
            {searchBar}
            <ul className="list-group">
                {sorted.map(x => {
                    const badge = generateBadge(x.id);

                    return (<div key={x.id}><li key={x.id} className="list-group-item d-flex justify-content-between align-items-center">
                        {badge}
                        {x.name}
                        <span>
                            <button className="btn btn-sm btn-outline-secondary" onClick={() => openClient(x.id)} type="submit">Детайли</button>
                            <span className="m-1"></span>
                            <button className="btn btn-sm btn-outline-danger" onClick={() => onDelete(x.id)} type="submit">Изтрий</button>
                        </span>
                    </li>
                    </div>);
                })}
            </ul>
        </div>);
    };

    const getForm = () => {
        return (<div>
            <h2 className="mb-4">Добави нов клиент</h2>
            <form className="needs-validation" id="createNewClientForm" noValidate>
                <div className="form-group mb-2">
                    <label htmlFor="clientName">Клиент</label>
                    <input type="text" className="form-control" id="clientName" placeholder="Въведете име на фирма" required />
                    <div id="ClientNameFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="bulstat">Булстат</label>
                    <input type="text" className="form-control" id="bulstat" placeholder="Въведете булстат на фирмата" required />
                    <div id="BulstatFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="address">Адрес</label>
                    <input type="text" className="form-control" id="address" placeholder="Въведете адрес на фирмата" required />
                    <div id="AddressFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="mol">МОЛ</label>
                    <input type="text" className="form-control" id="responsiblePerson" placeholder="Въведете отговорно лице за фирмата" />
                    <div id="ResponsiblePersonFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="email">Имейл</label>
                    <input type="email" className="form-control" id="email" placeholder="Въведете имейл адрес на фирмата" />
                    <div id="EmailFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="phoneNumber">Номер</label>
                    <input type="text" className="form-control" id="phoneNumber" placeholder="Въведете телефон за връзка" />
                    <div id="PhoneNumberFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <button type="submit" className="btn btn-outline-secondary" onClick={onClear} style={{ float: 'left' }}>Изчисти формата</button>
                <button type="submit" className="btn btn-outline-success" onClick={onCreateNew} style={{ float: 'right' }}>Добави нов клиент</button>
            </form>
        </div>);
    };

    const onSearchChange = (event) => {
        event.preventDefault();

        const value = event.target.value;

        if (value) {
            const filtered = clients.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);

            setSearchClients(filtered);
        } else {
            setSearchClients(clients);
        }
    };

    const getSearchBar = () => {
        return (<div>
            <div className="input-group mb-3">
                <input type="text" className="form-control" onChange={onSearchChange} placeholder="Търси по име на клиент или фирма" aria-label="Recipient's username" aria-describedby="basic-addon2" />
            </div>
        </div>);
    };

    const clientsRows = generateClientsRows(clients);
    const createNewClientForm = getForm();

    return (<div className="row">
        <div className="col-lg-6">
            {clientsRows}
        </div>
        <div className="col-lg-6">
            {createNewClientForm}
        </div>
    </div>);
};

export default Clients;