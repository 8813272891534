import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import DropdownMenuDetails from './DropdownMenu';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';

const Details = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState(location.state.title);
    const [incomes, setIncomes] = useState(null);
    const [expenses, setExpenses] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);

    if (title !== location.state.title) {
        setTitle(location.state.title);
    }

    const mapped = {
        'Входни суми': 'initialAmmount',
        'Фолио': 'foil',
        'Машини': 'machines',
        'Наем': 'rent',
        'Разходи': 'expenses',
    };

    const getAllIncomesRequest = async () => await CrudOperations.getAllIncomes();
    const getAllExpensesRequest = async () => await CrudOperations.getAllExpenses();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getIncomesData = async () => setIncomes(await getAllIncomesRequest());
        const getExpensesData = async () => setExpenses(await getAllExpensesRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getIncomesData();
        getExpensesData();
        checkIfLoggedIn();
    }, []);

    const extractAmmount = (record, type) => {
        const currentType = mapped[type];

        if (!currentType) {
            return `Няма информация за: ${type}`;
        }

        return record[currentType] || 0;
    };

    const generateTitleRow = () => {
        if (!title) {
            return 'Зареждане на разбивка...';
        }

        return (<h1 className="text-center title">Разбивка за {title.toLowerCase()}</h1>);
    };

    const onGoBack = (event) => {
        event.preventDefault();

        navigate('/incomes');
    };

    const titleRow = generateTitleRow();

    const generateIncomesAndExpensesTable = (data) => {
        const subTotalsMapped = {
            'Входни суми': 'currentInitialAmmountTotal',
            'Фолио': 'currentFoilTotal',
            'Машини': 'currentMachinesTotal',
            'Наем': 'currentRentTotal',
            'Разходи': 'currentExpensesTotal',
        };
        const propNeeded = subTotalsMapped[title];

        return (<div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Дата</th>
                        <th scope="col">Клиент</th>
                        <th scope="col">№ документ</th>
                        <th scope="col">Тип</th>
                        <th scope="col">Сума</th>
                        <th scope="col">Тотал</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(x => {
                        if (!x[propNeeded]) {
                            return;
                        }

                        if (x.initialAmmount) {
                            return (<tr key={x.id}>
                                <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                                <td>{x.clientName}</td>
                                <td>{x.documentNumber}</td>
                                <td><p className="text-success">Приход</p></td>
                                <td>{extractAmmount(x, title).toLocaleString()}</td>
                                <td>{x[propNeeded]}</td>
                            </tr>);
                        } else {
                            if (x[mapped[title]] === 0) {
                                return;
                            }

                            return (<tr key={x.id}>
                                <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                                <td>-</td>
                                <td>-</td>
                                <td><p className="text-danger">Разход</p></td>
                                <td>{extractAmmount(x, title).toLocaleString() === '0' ? '0' : '-' + extractAmmount(x, title).toLocaleString()}</td>
                                <td>{x[propNeeded]}</td>
                            </tr>);
                        }
                    })}
                </tbody>
            </table>
        </div>);
    };

    const PaginatedItems = (itemsPerPage) => {
        if (!incomes || !expenses) {
            return 'Генериране на таблица с приходи и разходи...';
        }

        const incomesAndExpenses = incomes.concat(expenses);
        incomesAndExpenses.sort((x, y) => new Date(y.date) - new Date(x.date));

        const endOffset = itemOffset + itemsPerPage;
        const currentItems = incomesAndExpenses.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(incomesAndExpenses.length / itemsPerPage);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % incomesAndExpenses.length;

            setItemOffset(newOffset);
        };

        const generatedTable = generateIncomesAndExpensesTable(currentItems);

        return (<div>
            {generatedTable}
            <ReactPaginate breakClassName={'page-item'} breakLinkClassName={'page-link'} containerClassName={'pagination'} pageClassName={'page-item'} pageLinkClassName={'page-link'}
                previousClassName={'page-item'} previousLinkClassName={'page-link'} nextClassName={'page-item'} nextLinkClassName={'page-link'} activeClassName={'active'}
                breakLabel="..." nextLabel="Следваща" onPageChange={handlePageClick} pageRangeDisplayed={5} pageCount={pageCount} previousLabel="Предишна" renderOnZeroPageCount={null} />
        </div>);
    };

    return (<div className="container">
        {titleRow}
        <hr />
        <button className="btn btn-outline-secondary" onClick={onGoBack}>Върни се обратно към разпределение на приходите</button>
        <span className="input-group-btn" style={{ float: 'right' }}>
            <DropdownMenuDetails></DropdownMenuDetails>
        </span>
        <hr />
        {PaginatedItems(20)}
    </div>);
};

export default Details;