import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';
import { Util } from '../util/Util';

const Home = () => {
    const navigate = useNavigate();

    const [folders, setFolders] = useState(null);
    const [loaded, setLoaded] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const getFoldersRequest = async () => await CrudOperations.getFolders();

    useEffect(() => {
        const getFolders = async () => {
            setFolders(await getFoldersRequest());

            if (!loaded) {
                setLoaded(true);
            }
        }
        const checkIfLoggedIn = async () => {
            const result = await CrudOperations.isAuthenticated();

            if (!result) {
                navigate('/login');
                return;
            }

            setIsUserLoggedIn(result);
        }

        getFolders();
        checkIfLoggedIn();
    }, []);

    const openFolder = async (folderId) => {
        try {
            const response = await fetch(`folders/getById?id=${folderId}`);
            const data = await response.json();

            if (data.success) {
                const mappedFolderNamesAndRoutes = {
                    'Материали склад': '/products',
                    'Поръчки': '/orders',
                    'Длъжници': '/clients',
                    'Приходи и разходи': '/incomes',
                    'Фактури': '/invoices'
                };
                const route = mappedFolderNamesAndRoutes[data.folderName];

                navigate(route);
            } else {
                Util.showError(`${data.error}`);
            }
        } catch (e) {
            Util.showError(e);
        }
    };

    // If new folder is added - uncomment and use this instead of first and second row functions.
    //const displayFolders = (folders) => {
    //    if (!folders || folders.length === 0) {
    //        return '';
    //    }

    //    const elements = folders.map(x => {
    //        return (<div className="col-md-3" key={x.id}>
    //            <div className="postHolder">
    //                <div className="card mb-3 box-shadow p-3">
    //                    <h4 className="text-center">{x.name}</h4>
    //                </div>
    //                <div className="card-body">
    //                    <p className="card-text text-center">{x.description}</p>
    //                </div>
    //            </div>
    //            <div className="btn-group buttonsHolder">
    //                <button className="btn btn-sm btn-outline-secondary" onClick={() => openFolder(x.id)}>Отвори</button>
    //            </div>
    //        </div>);
    //    });

    //    return elements;
    //};

    const generateFirstRow = (folders) => {
        if (!folders) {
            return '';
        }

        const secondRowIds = ['ECFC7A23-5F85-43AA-83DA-13DFD0003993', '85AEBA84-E7F6-437B-9BAB-B2BFD6E0A3DA', 'C2E114BA-95C5-43C3-B1CB-BA65E3D602F1'];
        const filtered = folders.filter(x => secondRowIds.indexOf(x.id.toUpperCase()) >= 0);

        if (!filtered || filtered.length === 0) {
            return;
        }

        return (<div className="row mb-5">
            <div className="col-md-4" key={filtered[0].id}>
                <div className="foldersHolder">
                    <div className="card mb-3 box-shadow p-3">
                        <h4 className="text-center">{filtered[0].name}</h4>
                    </div>
                    <div className="card-body">
                        <p className="card-text text-center">{filtered[0].description}</p>
                    </div>
                </div>
                <div className="btn-group buttonsHolder">
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => openFolder(filtered[0].id)}>Отвори</button>
                </div>
            </div>
            <div className="col-md-4" key={filtered[1].id}>
                <div className="foldersHolder">
                    <div className="card mb-3 box-shadow p-3">
                        <h4 className="text-center">{filtered[1].name}</h4>
                    </div>
                    <div className="card-body">
                        <p className="card-text text-center">{filtered[1].description}</p>
                    </div>
                </div>
                <div className="btn-group buttonsHolder">
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => openFolder(filtered[1].id)}>Отвори</button>
                </div>
            </div>
            <div className="col-md-4" key={filtered[2].id}>
                <div className="foldersHolder">
                    <div className="card mb-3 box-shadow p-3">
                        <h4 className="text-center">{filtered[2].name}</h4>
                    </div>
                    <div className="card-body">
                        <p className="card-text text-center">{filtered[2].description}</p>
                    </div>
                </div>
                <div className="btn-group buttonsHolder">
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => openFolder(filtered[2].id)}>Отвори</button>
                </div>
            </div>
        </div>);
    };

    const generateSecondRow = (folders) => {
        if (!folders) {
            return '';
        }

        const secondRowIds = ['57ACE216-FBFE-4C62-A370-0724814D1FA1', 'A7024BFD-901F-4BA4-A773-FD86B13E547F'];
        const filtered = folders.filter(x => secondRowIds.indexOf(x.id.toUpperCase()) >= 0);

        if (!filtered || filtered.length === 0) {
            return;
        }

        return (<div className="row">
            <div className="col-md-4" key={filtered[0].id}>
                <div className="foldersHolder">
                    <div className="card mb-3 box-shadow p-3">
                        <h4 className="text-center">{filtered[0].name}</h4>
                    </div>
                    <div className="card-body">
                        <p className="card-text text-center">{filtered[0].description}</p>
                    </div>
                </div>
                <div className="btn-group buttonsHolder">
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => openFolder(filtered[0].id)}>Отвори</button>
                </div>
            </div>
            <div className="col-md-4 offset-md-4" key={filtered[1].id}>
                <div className="foldersHolder">
                    <div className="card mb-3 box-shadow p-3">
                        <h4 className="text-center">{filtered[1].name}</h4>
                    </div>
                    <div className="card-body">
                        <p className="card-text text-center">{filtered[1].description}</p>
                    </div>
                </div>
                <div className="btn-group buttonsHolder">
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => openFolder(filtered[1].id)}>Отвори</button>
                </div>
            </div>
        </div>);
    };

    const firstRow = loaded ? generateFirstRow(folders) : 'Папките се зареждат...';
    const secondRow = loaded ? generateSecondRow(folders) : 'Папките се зареждат...';

    const toggleAuthenticationButtons = () => {
        if (isUserLoggedIn) {
            Util.toggleButtonsForLoggedUser();
        } else {
            Util.toggleButtonsForNotLoggedUser();
        }
    };

    toggleAuthenticationButtons();

    return (
        <div>
            <div className="album py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-5">Всички налични папки</h2>
                    <div>{firstRow}</div>
                    <div>{secondRow}</div>
                </div>
            </div>
        </div>
    );
}

export default Home;
