import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { CrudOperations } from '../util/CrudOperations';
import { Util } from '../util/Util';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "react-datepicker/dist/react-datepicker.css";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '390px'//'65%'
    }
};

const customStylesSearch = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '350px'
    }
};

const Orders = () => {
    const navigate = useNavigate();

    // Data
    const [orders, setOrders] = useState(null);
    const [clients, setClients] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    // Modals
    const [newOrderModalIsOpen, setNewOrderModalIsOpen] = useState(false);
    const [searchConfigurationModalIsOpen, setSearchConfigurationNewOrderModalIsOpen] = useState(false);
    // Search options
    const [specificMonthAndYear, setSpecificMonthAndYear] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    // Search configuration
    const [customSearchIsApplied, setCustomSearchIsApplied] = useState(false);
    const [customSearchConfiguration, setCustomSearchConfiguration] = useState(null);
    // Sorting configuration
    const [sortingIsApplied, setSortingIsApplied] = useState(false);
    const [sortingConfiguration, setSortingConfiguration] = useState(null);

    const getOrdersRequest = async () => await CrudOperations.getOrders();
    const getClientsRequest = async () => await CrudOperations.getClients();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getOrdersData = async () => setOrders(await getOrdersRequest());
        const getClientsData = async () => setClients(await getClientsRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getOrdersData();
        getClientsData();
        checkIfLoggedIn();
    }, []);

    const onAddNewOrder = async (event) => {
        event.preventDefault();

        const form = document.getElementById('newOrderForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const clientsInput = document.getElementById('clientsPicker');
        const designInput = document.getElementById('designInput');
        const descriptionInput = document.getElementById('descriptionInput');
        const kilogramsInput = document.getElementById('kilogramsInput');
        const linearMetresInput = document.getElementById('linearMetresInput');
        const includePrintInput = document.getElementById('includePrintInput');
        const includeLaminatingInput = document.getElementById('includeLaminatingInput');
        const includeCuttingInput = document.getElementById('includeCuttingInput');

        const clientsDefaultValue = 'Избери клиент от съществуващите';
        const clientsValue = clientsInput.value;
        const designValue = designInput.value;
        const descriptionValue = descriptionInput.value;
        const kilogramsValue = kilogramsInput.value;
        const linearMetresValue = linearMetresInput.value;
        const includePrintValue = includePrintInput.checked;
        const includeLaminatingValue = includeLaminatingInput.checked;
        const includeCuttingValue = includeCuttingInput.checked;

        const clientFeedback = document.getElementById('ClientFeedback');
        const designFeedback = document.getElementById('DesignFeedback');
        if (clientsValue === clientsDefaultValue) {
            clientFeedback.setHTML('Изборът на клиент е задължителен.');
            clientFeedback.classList.remove('hiddenElement');
            clientFeedback.classList.add('visibleElement');
        } else {
            //clientFeedback.setHTML('');
            //clientFeedback.classList.add('hiddenElement');
            //clientFeedback.classList.remove('visibleElement');
        }

        if (!designValue || designValue.length === 0) {
            designFeedback.setHTML('Полето за дизайн не може да бъде празно.');
            designFeedback.classList.remove('hiddenElement');
            designFeedback.classList.add('visibleElement');
        } else {
            //designFeedback.setHTML('');
            //designFeedback.classList.add('hiddenElement');
            //designFeedback.classList.remove('visibleElement');
        }

        if (clientsValue === clientsDefaultValue || !designValue || designValue.length === 0) {
            return;
        }

        const order = {};
        order.client = clients.filter(x => x.name === clientsValue)[0].id;
        order.design = designValue;
        order.description = descriptionValue;
        order.kilograms = kilogramsValue;
        order.linearMetres = linearMetresValue;
        order.includePrint = includePrintValue;
        order.includeLaminating = includeLaminatingValue;
        order.includeCutting = includeCuttingValue;

        await CrudOperations.newOrder(order);
        setOrders(await getOrdersRequest());
        setNewOrderModalIsOpen(false);
    };

    const openModal = (event) => {
        // "Добави нова", "Конфигурирай търсене"
        const choice = event.currentTarget.innerHTML;

        if (choice === 'Добави нова') {
            setNewOrderModalIsOpen(true);
        } else if (choice === 'Конфигурирай търсене') {
            setSearchConfigurationNewOrderModalIsOpen(true);
        }
    };

    const closeModal = (event) => {
        if (!event) {
            setNewOrderModalIsOpen(false);
            setSearchConfigurationNewOrderModalIsOpen(false);
        }

        // Two options - 'newOrderForm', 'searchForm'
        const modalType = event.currentTarget.parentElement.name;

        if (modalType === 'newOrderForm') {
            setNewOrderModalIsOpen(false);
        } else if (modalType === 'searchForm') {
            setSearchConfigurationNewOrderModalIsOpen(false);
        }
    };

    const populateClientsPicker = () => {
        if (!clients) {
            return;
        }

        const picker = document.getElementById('clientsPicker');

        if (!picker) {
            return;
        }

        clients.forEach(c => {
            const item = document.createElement("option");
            const itemText = document.createTextNode(c.name);

            item.appendChild(itemText);
            picker.appendChild(item);
        });
    }

    const onCheckboxClick = async (item, id, active) => {
        const element = document.getElementById(id);
        const value = element.checked;
        const splitted = id.split('_');
        const type = splitted[0];
        const mapped = {
            'print': 'печат',
            'laminated': 'каширане',
            'cut': 'рязане'
        };
        const activeMessage = `Сигурен ли си, че искаш да отбележиш ${mapped[type]} като ${value ? 'завършена' : 'незавършена'} операция? Ако всички необходими операции за тази поръчка са отбелязани като изпълнени, тя ще бъде маркирана като завършена.`;
        const inActiveMessage = `Сигурен ли си, че искаш да отбележиш ${mapped[type]} като ${value ? 'завършена' : 'незавършена'} операция? Ако дори една от необходимите операции за тази поръчка е отбелязана като неизпълнена, поръчката ще бъде маркирана като активна.`;

        try {
            confirmAlert({
                title: 'Потвърждение за маркиране',
                message: active ? activeMessage : inActiveMessage,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        if (type === 'print') {
                            item.printed = value;
                        } else if (type === 'laminated') {
                            item.laminated = value;
                        } else if (type === 'cut') {
                            item.cut = value;
                        }

                        const result = await CrudOperations.updateOrder(item);

                        if (result.success) {
                            setOrders(await getOrdersRequest());

                            return;
                        } else {
                            Util.showError(`Неуспешно маркиране на операцията.`);
                            element.checked = !value;

                            return;
                        }
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        element.checked = !value;

                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    };

    const generateCheckboxElement = (property, item, active) => {
        if (property === 'printed') {
            return item.includePrint ? <input className="form-check-input" id={`print_${item.id}`} onClick={() => onCheckboxClick(item, `print_${item.id}`, active)} type="checkbox" defaultChecked={item.printed} /> : '-';
        } else if (property === 'laminated') {
            return item.includeLaminating ? <input className="form-check-input" id={`laminated_${item.id}`} onClick={() => onCheckboxClick(item, `laminated_${item.id}`, active)} type="checkbox" defaultChecked={item.laminated} /> : '-';
        } else if (property === 'cut') {
            return item.includeCutting ? <input className="form-check-input" id={`cut_${item.id}`} onClick={() => onCheckboxClick(item, `cut_${item.id}`, active)} type="checkbox" defaultChecked={item.cut} /> : '-';
        } else {
            return '';
        }
    };

    const deleteOrder = async (id) => {
        try {
            confirmAlert({
                title: 'Потвърждение за изтриване',
                message: `Сигурен ли си, че искаш да изтриеш тази поръчка от системата?`,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        const error = await CrudOperations.deleteOrder(id);

                        if (error) {
                            Util.showError(`Не е открит запис за поръчката. Error message: ${error}`);
                        } else {
                            setOrders(await getOrdersRequest());
                        }
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    };

    const generateRows = (data, active, showAll = false) => {
        if (!data) {
            return '';
        }

        let filtered = [];
        if (!customSearchIsApplied) {
            const dataBasedOnActiveStatus = active ? data.filter(x => x.active) : data.filter(x => !x.active);

            if (showAll) {
                filtered = dataBasedOnActiveStatus;
            } else {
                filtered = dataBasedOnActiveStatus.filter(x => new Date(x.dateCreated).getFullYear() === new Date().getFullYear() && new Date(x.dateCreated).getMonth() === new Date().getMonth());
            }
        } else {
            const type = customSearchConfiguration.type;
            const dataBasedOnActiveStatus = active ? data.filter(x => x.active) : data.filter(x => !x.active);

            if (type === 'currentMonth') {
                filtered = dataBasedOnActiveStatus.filter(x => new Date(x.dateCreated).getFullYear() === new Date().getFullYear() && new Date(x.dateCreated).getMonth() === new Date().getMonth());
            } else if (type === 'currentYear') {
                filtered = dataBasedOnActiveStatus.filter(x => new Date(x.dateCreated).getFullYear() === new Date().getFullYear());
            } else if (type === 'specificMonthAndYear') {
                filtered = dataBasedOnActiveStatus.filter(x => new Date(x.dateCreated).getFullYear() === customSearchConfiguration.year && new Date(x.dateCreated).getMonth() === customSearchConfiguration.month - 1); // -1 because .getMonth() returns 0 based.
            } else if (type === 'rangeFromTo') {
                const startDateFormat = `${customSearchConfiguration.fromYear}-${customSearchConfiguration.fromMonth}-01`;
                const endDateFormat = `${customSearchConfiguration.toYear}-${customSearchConfiguration.toMonth}-01`;
                const startDate = new Date(startDateFormat);
                const endDate = new Date(endDateFormat);

                filtered = orders.filter(x => new Date(x.dateCreated) >= startDate && new Date(x.dateCreated) <= endDate);
            }
        }

        if (!sortingIsApplied || !sortingConfiguration) {
            filtered.sort((x, y) => new Date(y.dateCreated) - new Date(x.dateCreated));
        } else {
            const columnToSortBy = sortingConfiguration.type;
            const order = sortingConfiguration.order;

            if (columnToSortBy === 'Дата') {
                if (order === 'въз.') {
                    filtered.sort((x, y) => new Date(x.dateCreated) - new Date(y.dateCreated));
                } else if (order === 'низ.') {
                    filtered.sort((x, y) => new Date(y.dateCreated) - new Date(x.dateCreated));
                } else {
                    // ignore
                }
            } else if (columnToSortBy === 'Клиент') {
                if (order === 'въз.') {
                    filtered.sort((x, y) => {
                        const xClientName = getClientName(x.client);
                        const yClientName = getClientName(y.client);

                        return xClientName.localeCompare(yClientName);
                    });
                } else if (order === 'низ.') {
                    filtered.sort((x, y) => {
                        const xClientName = getClientName(x.client);
                        const yClientName = getClientName(y.client);

                        return yClientName.localeCompare(xClientName);
                    });
                } else {
                    // ignore
                }
            } else {
                // ignore
            }
        }

        return (<table className="table table-hover mb-5">
            <thead>
                <tr>
                    <th scope="col" onClick={() => onSortingConfiguration('Дата')}>{generateColumnName('Дата')}</th>
                    <th scope="col" onClick={() => onSortingConfiguration('Клиент')}>{generateColumnName('Клиент')}</th>
                    <th scope="col">Дизайн</th>
                    <th scope="col">Описание</th>
                    <th scope="col">Килограми</th>
                    <th scope="col">Лм</th>
                    <th scope="col">Печат</th>
                    <th scope="col">Каширане</th>
                    <th scope="col">Рязане</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {filtered.map(x => {
                    const clientName = getClientName(x.client);

                    return (<tr key={x.id} id={`line_${x.id}`}>
                        <th scope="row">{new Date(x.dateCreated).toLocaleString("bg-BG")}</th>
                        <td>{clientName}</td>
                        <td>{x.design}</td>
                        <td>{x.description}</td>
                        <td>{x.kilograms}</td>
                        <td>{x.linearMetres}</td>
                        <td>{generateCheckboxElement('printed', x, active)}</td>
                        <td>{generateCheckboxElement('laminated', x, active)}</td>
                        <td>{generateCheckboxElement('cut', x, active)}</td>
                        <td><button className="btn btn-sm btn-outline-danger" onClick={() => deleteOrder(x.id)}>Изтриване</button></td>
                    </tr>);
                })}
            </tbody>
        </table>);
    };

    const radioButtonSelected = (event) => {
        const selection = event.target.name;
        const currentMonthRadio = document.getElementById('currentMonth');
        const currentYearRadio = document.getElementById('currentYear');
        const specificMonthAndYearRadio = document.getElementById('specificMonthAndYear');
        const rangeFromToRadio = document.getElementById('rangeFromTo');

        const specificMonthAndYearPicker = document.getElementById('specificMonthAndYearPicker');
        const rangeFromToPicker = document.getElementById('rangeFromToPicker');

        if (selection === 'currentMonth') {
            currentYearRadio.checked = false;
            specificMonthAndYearRadio.checked = false;
            rangeFromToRadio.checked = false;

            specificMonthAndYearPicker.classList.add('hiddenElement2');
            specificMonthAndYearPicker.classList.remove('visibleElement');
            rangeFromToPicker.classList.add('hiddenElement2');
            rangeFromToPicker.classList.remove('visibleElement');
        } else if (selection === 'currentYear') {
            currentMonthRadio.checked = false;
            specificMonthAndYearRadio.checked = false;
            rangeFromToRadio.checked = false;

            specificMonthAndYearPicker.classList.add('hiddenElement2');
            specificMonthAndYearPicker.classList.remove('visibleElement');
            rangeFromToPicker.classList.add('hiddenElement2');
            rangeFromToPicker.classList.remove('visibleElement');
        } else if (selection === 'specificMonthAndYear') {
            currentMonthRadio.checked = false;
            currentYearRadio.checked = false;
            rangeFromToRadio.checked = false;

            specificMonthAndYearPicker.classList.remove('hiddenElement2');
            specificMonthAndYearPicker.classList.add('visibleElement');
            rangeFromToPicker.classList.add('hiddenElement2');
            rangeFromToPicker.classList.remove('visibleElement');
        } else if (selection === 'rangeFromTo') {
            currentMonthRadio.checked = false;
            currentYearRadio.checked = false;
            specificMonthAndYearRadio.checked = false;

            specificMonthAndYearPicker.classList.add('hiddenElement2');
            specificMonthAndYearPicker.classList.remove('visibleElement');
            rangeFromToPicker.classList.remove('hiddenElement2');
            rangeFromToPicker.classList.add('visibleElement');
        } else {
            return;
        }
    };

    const cleanSearch = () => {
        setCustomSearchIsApplied(false);
        setCustomSearchConfiguration(null);
    };

    const cleanSorting = () => {
        setSortingIsApplied(false);
        setSortingConfiguration(null);
    };

    const onSortingConfiguration = (type) => {
        const config = {
            type: null,
            order: null
        };

        if (type === 'Дата' || type === 'Клиент') {
            if (sortingConfiguration && sortingConfiguration.order) {
                config.type = type;
                config.order = sortingConfiguration.order === 'низ.' ? 'въз.' : 'низ.';
            } else {
                config.type = type;
                config.order = config.order ? 'въз.' : 'низ.';
            }

            setSortingIsApplied(true);
            setSortingConfiguration(config);
        } else {
            return;
        }
    };

    const generateColumnName = (type) => {
        if (type === 'Дата' || type === 'Клиент') {
            if (!sortingIsApplied || !sortingConfiguration || sortingConfiguration.type !== type) {
                return type;
            } else {
                return `${type} ${sortingConfiguration.order}`;
            }
        } else {
            return type;
        }
    };

    const getClientName = (id) => {
        if (!clients || !id) {
            return '';
        }

        const foundClient = clients.filter(c => c.id.toLowerCase() === id.toLowerCase())[0];

        if (!foundClient) {
            return '';
        }

        return foundClient.name;
    };

    const doSearch = (event) => {
        event.preventDefault();

        const currentMonthRadio = document.getElementById('currentMonth');
        const currentYearRadio = document.getElementById('currentYear');
        const specificMonthAndYearRadio = document.getElementById('specificMonthAndYear');
        const rangeFromToRadio = document.getElementById('rangeFromTo');

        const configuration = {
            type: '',
            month: '',
            year: '',
            fromMonth: '',
            fromYear: '',
            toMonth: '',
            toYear: ''
        };

        if (currentMonthRadio.checked) {
            configuration.type = 'currentMonth';

            setCustomSearchIsApplied(true);
            setCustomSearchConfiguration(configuration);
        } else if (currentYearRadio.checked) {
            configuration.type = 'currentYear';

            setCustomSearchIsApplied(true);
            setCustomSearchConfiguration(configuration);
        } else if (specificMonthAndYearRadio.checked) {
            configuration.type = 'specificMonthAndYear';
            configuration.month = specificMonthAndYear.getMonth() + 1;
            configuration.year = specificMonthAndYear.getFullYear();

            setCustomSearchIsApplied(true);
            setCustomSearchConfiguration(configuration);
        } else if (rangeFromToRadio.checked) {
            configuration.type = 'rangeFromTo';
            configuration.fromMonth = fromDate.getMonth() + 1;
            configuration.fromYear = fromDate.getFullYear();
            configuration.toMonth = toDate.getMonth() + 1;
            configuration.toYear = toDate.getFullYear();

            setCustomSearchIsApplied(true);
            setCustomSearchConfiguration(configuration);
        }

        setSearchConfigurationNewOrderModalIsOpen(false);
    };

    const generateInfoMessage = (orders, customSearchAvailable) => {
        if (!orders) {
            return '';
        }

        if (!customSearchAvailable) {
            const forThisMonth = orders.filter(x => new Date(x.dateCreated).getFullYear() === new Date().getFullYear() && new Date(x.dateCreated).getMonth() === new Date().getMonth());

            return (<blockquote className="blockquote mb-3">
                <p>Показаните резултати са само за текущия месец. За по-конкретно търсене използвай конфигуриране на търсене.</p>
                <footer className="blockquote-footer">Получен резултат: активни поръчки <cite title="Source Title">{forThisMonth.length > 0 ? forThisMonth.filter(x => x.active).length : 0}</cite>, изпълнени поръчки: <cite title="Source Title">{forThisMonth.length > 0 ? forThisMonth.filter(x => !x.active).length : 0}</cite></footer>
            </blockquote>);
        } else {
            const type = customSearchConfiguration.type;
            let paragraph = '';
            let result = [];

            if (type === 'currentMonth') {
                result = orders.filter(x => new Date(x.dateCreated).getFullYear() === new Date().getFullYear() && new Date(x.dateCreated).getMonth() === new Date().getMonth());
                paragraph = `Показаните резултати са за текущия месец - ${Util.getMonthFromIndex([new Date().getMonth()])}. За промяна конфигурирай ново търсене.`;
            } else if (type === 'currentYear') {
                result = orders.filter(x => new Date(x.dateCreated).getFullYear() === new Date().getFullYear());
                paragraph = `Показаните резултати са за цялата ${new Date().getFullYear()} година. За промяна конфигурирай ново търсене.`;
            } else if (type === 'specificMonthAndYear') {
                result = orders.filter(x => new Date(x.dateCreated).getFullYear() === customSearchConfiguration.year && new Date(x.dateCreated).getMonth() === customSearchConfiguration.month - 1); // -1 because .getMonth() returns 0 based.
                paragraph = `Показаните резултати са за ${Util.getMonthFromIndex([customSearchConfiguration.month] - 1)} ${customSearchConfiguration.year} година. За промяна конфигурирай ново търсене.`;
            } else if (type === 'rangeFromTo') {
                const startDateFormat = `${customSearchConfiguration.fromYear}-${customSearchConfiguration.fromMonth}-01`;
                const endDateFormat = `${customSearchConfiguration.toYear}-${customSearchConfiguration.toMonth}-01`;
                const startDate = new Date(startDateFormat);
                const endDate = new Date(endDateFormat);

                result = orders.filter(x => new Date(x.dateCreated) >= startDate && new Date(x.dateCreated) <= endDate);
                paragraph = `Показаните резултати са за периода от ${Util.getMonthFromIndex([customSearchConfiguration.fromMonth] - 1)} ${customSearchConfiguration.fromYear} до ${Util.getMonthFromIndex([customSearchConfiguration.toMonth] - 1)} ${customSearchConfiguration.toYear} година. За промяна конфигурирай ново търсене.`;
            }

            return (<blockquote className="blockquote mb-3">
                <p>{paragraph}</p>
                <footer className="blockquote-footer">Получен резултат: активни поръчки <cite title="Source Title">{result && result.length > 0 ? result.filter(x => x.active).length : 0}</cite>, изпълнени поръчки: <cite title="Source Title">{result && result.length > 0 ? result.filter(x => !x.active).length : 0}</cite></footer>
            </blockquote>);
        }
    };

    const infoMessage = generateInfoMessage(orders, customSearchIsApplied);
    const activeOrders = generateRows(orders, true);
    const completedOrders = generateRows(orders, false);
    const allActiveOrders = generateRows(orders, true, true);

    return (<div>
        <h1 className="text-center mb-5">Информация за поръчки</h1>
        {infoMessage}
        <button className="btn btn-md btn-outline-primary" onClick={openModal}>Добави нова</button>
        <Modal isOpen={newOrderModalIsOpen} onAfterOpen={populateClientsPicker} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нова поръчка">
            <h2 className="text-center mb-3">Добави нова поръчка</h2>
            <form className="needs-validation" id="newOrderForm" noValidate name="newOrderForm">
                <div className="row">
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Клиент:
                            <select id="clientsPicker" name="clientsPicker" className="form-select">
                                <option defaultValue>Избери клиент от съществуващите</option>
                            </select>
                        </label>
                        <div id="ClientFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Дизайн:
                            <input className="form-control" id="designInput" name="design" type="text" required />
                        </label>
                        <div id="DesignFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Описание:
                            <textarea className="form-control" id="descriptionInput" name="description"></textarea>
                        </label>
                        <div id="DescriptionFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Килограми:
                            <input className="form-control" id="kilogramsInput" name="kilograms" type="number" min="0" step=".01" required />
                        </label>
                        <div id="KilogramsFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-4 offset-md-4">
                        <label className="form-label">Линейни метри:
                            <input className="form-control" id="linearMetresInput" name="linearMetres" type="number" min="0" step=".01" required />
                        </label>
                        <div id="LinearMetresFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-3 col-md-4">
                        <label htmlFor="includePrintingInput" className="form-check-label">Включи в процеса печат:
                            <input className="form-check-input m-2" id="includePrintInput" name="includePrint" type="checkbox" />
                        </label>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label htmlFor="includeLaminatingInput" className="form-check-label">Включи в процеса каширане:
                            <input className="form-check-input m-2" id="includeLaminatingInput" name="includeLaminating" type="checkbox" />
                        </label>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label htmlFor="includeCuttingInput" className="form-check-label">Включи в процеса рязане:
                            <input className="form-check-input m-2" id="includeCuttingInput" name="includeCutting" type="checkbox" />
                        </label>
                    </div>
                </div>
                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onAddNewOrder}>Създай</button>
            </form>
        </Modal>
        <button className="btn m-2 btn-md btn-outline-secondary" style={{ float: 'right' }} onClick={openModal}>Конфигурирай търсене</button>
        <button className="btn m-2 btn-md btn-outline-secondary" style={{ float: 'right' }} onClick={cleanSearch}>Изчисти търсене</button>
        <button className="btn m-2 btn-md btn-outline-secondary" style={{ float: 'right' }} onClick={cleanSorting}>Изчисти сортиране</button>
        <Modal isOpen={searchConfigurationModalIsOpen} onRequestClose={closeModal} style={customStylesSearch} contentLabel="Конфигуриране на търсене">
            <h2 className="text-center mb-3">Конфигуриране на търсене</h2>
            <form className="needs-validation" id="searchForm" noValidate name="searchForm">
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="currentMonth" id="currentMonth" value="currentMonth" onClick={radioButtonSelected} defaultChecked />
                            <label className="form-check-label" htmlFor="currentMonth">За този месец</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="currentYear" id="currentYear" value="currentYear" onClick={radioButtonSelected} />
                            <label className="form-check-label" htmlFor="currentYear">За тази година</label>
                        </div>
                        <div className="form-check disabled">
                            <input className="form-check-input" type="radio" name="specificMonthAndYear" id="specificMonthAndYear" value="specificMonthAndYear" onClick={radioButtonSelected} />
                            <label className="form-check-label" htmlFor="specificMonthAndYear">Избор на конкретен месец и година</label>
                        </div>
                        <div className="form-check disabled">
                            <input className="form-check-input" type="radio" name="rangeFromTo" id="rangeFromTo" value="rangeFromTo" onClick={radioButtonSelected} />
                            <label className="form-check-label" htmlFor="rangeFromTo">Избор от дата до дата</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div id="specificMonthAndYearPicker" className="form-check hiddenElement2">
                            <label className="form-check-label">Избор на конкретен месец и година</label>
                            <DatePicker selected={specificMonthAndYear} dateFormat="MM/yyyy" onChange={(date) => setSpecificMonthAndYear(date)} showMonthYearPicker onKeyDown={(e) => { e.preventDefault(); }} />
                        </div>
                        <div id="rangeFromToPicker" className="form-check hiddenElement2">
                            <label className="form-check-label">Месец и година от</label>
                            <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} selectsStart startDate={fromDate} endDate={toDate} dateFormat="MM/yyyy" showMonthYearPicker onKeyDown={(e) => { e.preventDefault(); }} />
                            <label className="form-check-label">Месец и година до</label>
                            <DatePicker selected={toDate} onChange={(date) => setToDate(date)} selectsEnd startDate={fromDate} endDate={toDate} dateFormat="MM/yyyy" showMonthYearPicker onKeyDown={(e) => { e.preventDefault(); }} />
                        </div>
                    </div>
                </div>
                <p className="hiddenElement">test</p>
                <p className="hiddenElement">test</p>
                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={doSearch}>Търсене</button>
            </form>
        </Modal>
        <div className="row">
            <p></p>
        </div>
        <div className="row">
            <h3 className="text-center">Всички активни поръчки</h3>
            <div className="col-sm-12">
                {allActiveOrders}
            </div>
        </div>
        <div className="row">
            <h3 className="text-center">Активни поръчки</h3>
            <div className="col-sm-12">
                {activeOrders}
            </div>
        </div>
        <div className="row">
            <h3 className="text-center">Изпълнени поръчки</h3>
            <div className="col-sm-12">
                {completedOrders}
            </div>
        </div>
    </div>);
};

export default Orders;