import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const FoldersDropdown = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret size="md" className="navigationDropdown">Папки</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => navigate('/clients')}>Длъжници</DropdownItem>
                <DropdownItem onClick={() => navigate('/products')}>Материали склад</DropdownItem>
                <DropdownItem onClick={() => navigate('/orders')}>Поръчки</DropdownItem>
                <DropdownItem onClick={() => navigate('/incomes')}>Приходи и разходи</DropdownItem>
                <DropdownItem onClick={() => navigate('/invoices')}>Фактури</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default FoldersDropdown;