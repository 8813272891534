import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';

const Users = () => {
    const navigate = useNavigate();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [users, setUsers] = useState(null);

    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();
    const getUsersRequest = async () => await CrudOperations.getUsers();

    useEffect(() => {
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };
        const getUsers = async () => setUsers(await getUsersRequest());

        checkIfLoggedIn();
        getUsers();
    }, []);

    return (<div>
        <h1>Потребители</h1>
    </div>);
};

export default Users;