import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Util } from '../util/Util';
import { CrudOperations } from '../util/CrudOperations';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%'
    }
};

Modal.setAppElement(document.getElementById('root'));

const ClientInfo = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [clients, setClients] = useState(null);
    const [clientInfo, setClientInfo] = useState(location.state.clientData);
    const [obligationsForClient, setObligationsForClient] = useState(null);
    const [cameFrom, setCameFrom] = useState(location.state.cameFrom);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    // Modals
    const [addObligationModalIsOpen, setAddObligationModalIsOpen] = useState(false);
    const [editClientInfoIsOpen, setEditClientInfoIsOpen] = useState(false);
    const [addIncomeModalIsOpen, setAddIncomeModalIsOpen] = useState(false);
    // Edit client
    const [clientName, setClientName] = useState(null);
    const [bulstat, setBulstat] = useState(null);
    const [address, setAddress] = useState(null);
    const [responsiblePerson, setResponsiblePerson] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    // Pay obligation
    const [paidObligation, setPaidObligation] = useState(null);
    const [paidObligationId, setPaidObligationId] = useState(null);

    const getObligationsForClientRequest = async () => await CrudOperations.getObligationsForClient(clientInfo.id);
    const getClientsRequest = async () => await CrudOperations.getClients();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getObligationsForClient = async () => setObligationsForClient(await getObligationsForClientRequest());
        const getClientsData = async () => setClients(await getClientsRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getObligationsForClient();
        getClientsData();
        checkIfLoggedIn();
    }, []);

    const onGoBack = (event) => {
        event.preventDefault();

        navigate(cameFrom);
    };

    const addNewObligation = async (event) => {
        event.preventDefault();

        const invoiceNumberInput = document.getElementById('invoiceNumberInput');
        const stockNumberInput = document.getElementById('stockNumberInput');
        const batchNumberInput = document.getElementById('batchNumberInput');
        const ammountInput = document.getElementById('ammountInput');
        const descriptionInput = document.getElementById('descriptionInput');

        const form = document.getElementById('addObligationForm');
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');

            return;
        }

        const invoiceNumber = invoiceNumberInput.value;
        const stockNumber = stockNumberInput.value;
        const batchNumber = batchNumberInput.value;
        const ammount = ammountInput.value;
        const description = descriptionInput.value;

        const obligation = {
            clientId: clientInfo.id,
            invoiceNumber,
            stockNumber,
            batchNumber,
            ammount,
            description,
            paid: false
        };

        const result = await CrudOperations.createNewObligation(obligation);

        if (result.success) {
            const obligationsResult = await getObligationsForClientRequest();

            closeModal();
            setObligationsForClient(obligationsResult);
        } else {
            event.stopPropagation();

            const errors = result.errors;
            const keys = Object.keys(errors);
            form.classList.add('was-validated');

            keys.forEach(key => {
                const error = errors[key][0];
                const feedbackId = `${key.trim()}Feedback`;
                const feedbackElement = document.getElementById(feedbackId);

                if (feedbackElement) {
                    feedbackElement.setHTML(error);
                    feedbackElement.classList.remove('hiddenElement');
                    feedbackElement.classList.add('visibleElement');
                    feedbackElement.previousElementSibling.value = '';

                    Util.showError(error);
                }
            });

            Util.showError(`Неуспешно записване на задължение за клиент: ${clientInfo.name} на стойност: ${ammount}`);
        }
    };

    const setObligationAsPaid = async (obligationInfo) => {
        confirmAlert({
            title: 'Потвърждение за плащане',
            message: `Сигурен ли си, че искаш да маркираш фактура: ${obligationInfo.invoiceNumber} за ${obligationInfo.ammount.toLocaleString()} лв като платена?`,
            buttons: [{
                label: 'Да',
                onClick: async () => {
                    const success = await CrudOperations.setObligationAsPaid(obligationInfo.id);

                    if (success) {
                        const obligationsResult = await getObligationsForClientRequest();

                        setObligationsForClient(obligationsResult);
                        setPaidObligation(obligationInfo.ammount);
                        setPaidObligationId(obligationInfo.id);
                        setAddIncomeModalIsOpen(true);
                    } else {
                        Util.showError(`Неуспешно отбелязване на задължение за клиент: ${clientInfo.name} като платено.`);
                    }
                }
            }, {
                label: 'Не',
                onClick: () => {
                    return;
                }
            }],
            closeOnEscape: false,
            closeOnClickOutside: false
        });
    };

    const unMarkObligation = async (obligationInfo) => {
        confirmAlert({
            title: 'Потвърждение',
            message: `Сигурен ли си, че искаш да маркираш фактура: ${obligationInfo.invoiceNumber} за ${obligationInfo.ammount.toLocaleString()} лв като неплатена отново? Приходът, който е бил генериран от нея ще бъде изтрит.`,
            buttons: [{
                label: 'Да',
                onClick: async () => {
                    debugger;
                    const result = await CrudOperations.setObligationAsUnpaid(obligationInfo.id);
                    const incomeId = result.incomeId;

                    if (result.success) {
                        const obligationsResult = await getObligationsForClientRequest();

                        setObligationsForClient(obligationsResult);

                        if (incomeId) {
                            await CrudOperations.deleteIncome(incomeId);
                        }
                    } else {
                        Util.showError(`Неуспешно отбелязване на задължение за клиент: ${clientInfo.name} като платено.`);
                    }
                }
            }, {
                label: 'Не',
                onClick: () => {
                    return;
                }
            }],
            closeOnEscape: false,
            closeOnClickOutside: false
        });
    };

    const deleteObligation = async (obligationInfo) => {
        confirmAlert({
            title: 'Потвърждение за изтриване',
            message: `Сигурен ли си, че искаш да изтриеш фактура: ${obligationInfo.invoiceNumber} за ${obligationInfo.ammount.toLocaleString()} лв?`,
            buttons: [{
                label: 'Да',
                onClick: async () => {
                    const error = await CrudOperations.deleteObligation(obligationInfo.id);

                    if (error) {
                        Util.showError(`Не е открит запис за това задължение. Error message: ${error}`);
                    } else {
                        const obligationsResult = await getObligationsForClientRequest();

                        setObligationsForClient(obligationsResult);
                    }
                }
            }, {
                label: 'Не',
                onClick: () => {
                    return;
                }
            }],
            closeOnEscape: false,
            closeOnClickOutside: false
        });
    };

    const closeModal = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (addObligationModalIsOpen) {
            setAddObligationModalIsOpen(false);
        } else if (editClientInfoIsOpen) {
            setEditClientInfoIsOpen(false);
        } else if (addIncomeModalIsOpen) {
            setAddIncomeModalIsOpen(false);
        } else {
            Util.showError(`Неуспешен опит за затваряне от бутон: ${event.target.innerHTML}`);
        }
    };

    const openModal = (event) => {
        event.preventDefault();
        const target = event.target.innerHTML;

        if (target === 'Добави ново задължение') {
            setAddObligationModalIsOpen(true);
        } else if (target === 'Редактирай') {
            setClientName(clientInfo.name);
            setBulstat(clientInfo.bulstat);
            setAddress(clientInfo.address);
            setResponsiblePerson(clientInfo.responsiblePerson);
            setEmail(clientInfo.email);
            setPhoneNumber(clientInfo.phoneNumber);
            setEditClientInfoIsOpen(true);
        } else {
            Util.showError(`Не намира опция: ${target}`);
        }
    };

    const handleChange = (event) => {
        const target = event.target.name;
        const value = event.target.value;

        if (target === 'clientName') {
            setClientName(value);
        } else if (target === 'bulstat') {
            setBulstat(value);
        } else if (target === 'address') {
            setAddress(value);
        } else if (target === 'responsiblePerson') {
            setResponsiblePerson(value);
        } else if (target === 'email') {
            setEmail(value);
        } else if (target === 'phoneNumber') {
            setPhoneNumber(value);
        } else if (target === 'initialAmmount') {
            const initialAmmountValue = parseFloat(value);
            const foilInput = document.getElementById('foilInput');
            const machinesInput = document.getElementById('machinesInput');
            const rentInput = document.getElementById('rentInput');
            const expensesInput = document.getElementById('expensesInput');

            const calculatedFoil = 0.5 * initialAmmountValue;
            const calculatedMachines = 0.14 * initialAmmountValue;
            const calculatedRent = 0.05 * initialAmmountValue;
            const calculatedExpenses = initialAmmountValue - (calculatedFoil + calculatedMachines + calculatedRent);

            foilInput.value = calculatedFoil.toFixed(2);
            machinesInput.value = calculatedMachines.toFixed(2);
            rentInput.value = calculatedRent.toFixed(2);
            expensesInput.value = calculatedExpenses.toFixed(2);
        } else {
            alert(`no info for ${target}`);
        }
    };

    const editClient = async (event) => {
        event.preventDefault();

        const clientNameField = document.getElementById('clientName');
        const clientNameValue = clientName;
        const bulstatValue = bulstat;
        const addressValue = address;
        const responsiblePersonValue = responsiblePerson;
        const emailValue = email;
        const phoneNumberValue = phoneNumber;

        const form = document.getElementById('editClientInfoForm');
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');

            return;
        }

        const clientsWithThisName = clients.filter(x => x.name.toLowerCase() === clientNameValue.toLowerCase() && x.id.toLowerCase() !== clientInfo.id.toLowerCase());
        if (clientsWithThisName.length > 0) {
            event.stopPropagation();
            clientNameField.value = '';
            form.classList.add('was-validated');

            Util.showError(`Клиент с име ${clientNameValue} вече съществува.`);
            return;
        }

        const updatedClient = {
            name: clientNameValue,
            bulstat: bulstatValue,
            address: addressValue,
            responsiblePerson: responsiblePersonValue,
            email: emailValue,
            phoneNumber: phoneNumberValue,
            id: clientInfo.id
        };

        const result = await CrudOperations.updateClient(updatedClient);

        if (result.success) {
            const clientsResult = await getClientsRequest();
            const currentClient = clientsResult.filter(x => x.id.toLowerCase() === clientInfo.id.toLowerCase())[0];

            setClients(clientsResult);
            setClientInfo(currentClient);
        } else {
            event.stopPropagation();

            const errors = result.errors;
            const keys = Object.keys(errors);
            form.classList.add('was-validated');

            keys.forEach(key => {
                const error = errors[key][0];
                const feedbackId = `${key.trim()}Feedback`;
                const feedbackElement = document.getElementById(feedbackId);

                if (feedbackElement) {
                    feedbackElement.setHTML(error);
                    feedbackElement.classList.remove('hiddenElement');
                    feedbackElement.classList.add('visibleElement');
                    feedbackElement.previousElementSibling.value = '';

                    Util.showError(error);
                }
            });

            Util.showError(`Неуспешно записване на промяна за клиент.`);
            return;
        }

        setEditClientInfoIsOpen(false);
    };

    const addIncome = async (event) => {
        event.preventDefault();

        const initialAmmountInput = document.getElementById('initialAmmountInput');
        const foilInput = document.getElementById('foilInput');
        const machinesInput = document.getElementById('machinesInput');
        const rentInput = document.getElementById('rentInput');
        const expensesInput = document.getElementById('expensesInput');
        const clientNameInput = document.getElementById('clientNameInput');
        const documentNumberInput = document.getElementById('documentNumberInput');

        const initialAmmountValue = parseFloat(initialAmmountInput.value);
        const foilValue = parseFloat(foilInput.value) || 0;
        const machinesValue = parseFloat(machinesInput.value) || 0;
        const rentValue = parseFloat(rentInput.value) || 0;
        const expensesValue = parseFloat(expensesInput.value) || 0;

        if (initialAmmountValue !== foilValue + machinesValue + rentValue + expensesValue) {
            Util.showError(`Грешен запис. Прихода е ${initialAmmountValue}, а общата сума на останалите стойности е ${foilValue + machinesValue + rentValue + expensesValue}. Сумата трябва да бъде равна.`);

            return;
        }

        const form = document.getElementById('addIncomeForm');
        const income = {
            initialAmmount: initialAmmountValue,
            foil: foilValue,
            machines: machinesValue,
            rent: rentValue,
            expenses: expensesValue,
            clientName: clientNameInput.value,
            documentNumber: documentNumberInput.value,
            obligationId: paidObligationId
        };

        if (!initialAmmountValue || initialAmmountValue <= 0) {
            Util.showError('Приход не може да бъде по-малък или равен на 0.');

            return;
        }

        const result = await CrudOperations.createNewIncome(income);

        if (result.success) {
            setAddIncomeModalIsOpen(false);
        } else {
            event.stopPropagation();
            // In theory, we should never be here...
            const errors = result.errors;
            const keys = Object.keys(errors);
            form.classList.add('was-validated');

            keys.forEach(key => {
                const error = errors[key][0];
                const feedbackId = `${key.trim()}Feedback`;
                const feedbackElement = document.getElementById(feedbackId);

                if (feedbackElement) {
                    feedbackElement.setHTML(error);
                    feedbackElement.classList.remove('hiddenElement');
                    feedbackElement.classList.add('visibleElement');
                    feedbackElement.previousElementSibling.value = '';

                    Util.showError(error);
                }
            });

            Util.showError(`Неуспешно записване на приход от: ${initialAmmountValue}`);
        }
    };

    const onOpenModal = () => {
        const initialAmmountInput = document.getElementById('initialAmmountInput');
        const foilInput = document.getElementById('foilInput');
        const machinesInput = document.getElementById('machinesInput');
        const rentInput = document.getElementById('rentInput');
        const expensesInput = document.getElementById('expensesInput');
        const clientNameInput = document.getElementById('clientNameInput');

        initialAmmountInput.value = paidObligation;
        clientNameInput.value = clientInfo.name;
        const calculatedFoil = 0.5 * paidObligation;
        const calculatedMachines = 0.14 * paidObligation;
        const calculatedRent = 0.05 * paidObligation;
        const calculatedExpenses = paidObligation - (calculatedFoil + calculatedMachines + calculatedRent);

        foilInput.value = calculatedFoil.toFixed(2);
        machinesInput.value = calculatedMachines.toFixed(2);
        rentInput.value = calculatedRent.toFixed(2);
        expensesInput.value = calculatedExpenses.toFixed(2);
    };

    const getClientCard = () => {
        if (!clientInfo) {
            return 'Информация за клиента се подготвя...';
        }

        const infoMessage = generateInfoMessage();

        return (<div className="card text-center">
            <div className="card-header">
                {clientInfo.name}
            </div>
            <div className="card-body">
                <h4 className="card-title">Информация за клиента</h4>
                <p className="card-text">Булстат: <b>{clientInfo.bulstat}</b>.</p>
                <p className="card-text">Адрес: <b>{clientInfo.address}</b>.</p>
                <p className="card-text">МОЛ: <b>{clientInfo.responsiblePerson}</b>.</p>
                <p className="card-text">Имейл: <b>{clientInfo.email}</b>.</p>
                <p className="card-text">Телефон за връзка: <b>{clientInfo.phoneNumber}</b>.</p>
                <button className="btn btn-outline-secondary m-2" onClick={onGoBack}>Върни се обратно</button>
                <button className="btn btn-outline-secondary m-2" onClick={openModal}>Редактирай</button>
                <button className="btn btn-outline-success m-2" onClick={openModal}>Добави ново задължение</button>
                <Modal isOpen={addObligationModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави ново задължение">
                    <h2 className="text-center mb-3">Добави ново задължение</h2>
                    <form className="needs-validation" id="addObligationForm" name="addObligation" noValidate>
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="invoiceNumberInput" className="form-label">№ фактура:
                                    <input className="form-control" id="invoiceNumberInput" name="invoiceNumber" type="text" required />
                                    <div id="InvoiceNumberFeedback" className="invalid-feedback hiddenElement"></div>
                                </label>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="stockNumberInput" className="form-label">№ стокова:
                                    <input className="form-control" id="stockNumberInput" name="stockNumber" type="text" required />
                                    <div id="StockNumberFeedback" className="invalid-feedback hiddenElement"></div>
                                </label>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="batchNumberInput" className="form-label">Партиден №:
                                    <input className="form-control" id="batchNumberInput" name="batchNumber" type="text" required />
                                    <div id="BatchNumberFeedback" className="invalid-feedback hiddenElement"></div>
                                </label>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="ammountInput" className="form-label">Сума:
                                    <input className="form-control" id="ammountInput" name="ammount" type="number" step=".01" required />
                                    <div id="AmmountFeedback" className="invalid-feedback hiddenElement"></div>
                                </label>
                            </div>
                            <div className="form-group mb-3 col-md-4 offset-md-4">
                                <label className="form-label">Описание стока:
                                    <textarea className="form-control" id="descriptionInput"></textarea>
                                </label>
                            </div>
                        </div>
                        <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                        <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={addNewObligation}>Създай</button>
                    </form>
                </Modal>
                <Modal isOpen={editClientInfoIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Редактирай информация за клиент">
                    <h2 className="text-center mb-3">Редактирай информация за клиент</h2>
                    <form className="needs-validation" id="editClientInfoForm" noValidate>
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="clientName">Клиент</label>
                                <input type="text" className="form-control" id="clientName" name="clientName" onChange={handleChange} value={clientName} required />
                                <div id="ClientNameFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="bulstat">Булстат</label>
                                <input type="text" className="form-control" name="bulstat" onChange={handleChange} value={bulstat} required />
                                <div id="BulstatFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="address">Адрес</label>
                                <input type="text" className="form-control" name="address" onChange={handleChange} value={address} required />
                                <div id="AddressFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="mol">МОЛ</label>
                                <input type="text" className="form-control" name="responsiblePerson" onChange={handleChange} value={responsiblePerson} required />
                                <div id="ResponsiblePersonFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="email">Имейл</label>
                                <input type="email" className="form-control" name="email" onChange={handleChange} value={email} required />
                                <div id="EmailFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label htmlFor="phoneNumber">Номер</label>
                                <input type="text" className="form-control" name="phoneNumber" onChange={handleChange} value={phoneNumber} required />
                                <div id="PhoneNumberFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                        </div>
                        <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                        <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={editClient}>Създай</button>
                    </form>
                </Modal>
            </div>
            {infoMessage}
        </div>);
    };

    const generateInfoMessage = () => {
        if (!obligationsForClient) {
            return `Задълженията за ${clientInfo.name} се събират...`;
        }

        if (obligationsForClient.length === 0) {
            return (<div className="card-footer text-success">Няма задължения чакащи плащане.</div>);
        }

        const pendingPayment = obligationsForClient.filter(x => !x.paid);

        if (pendingPayment.length === 0) {
            return (<div className="card-footer text-success">Няма задължения чакащи плащане.</div>);
        } else if (pendingPayment.length === 1) {
            return (<div className="card-footer text-danger">{pendingPayment.length} фактура със статус чакаща плащане.</div>);
        } else {
            return (<div className="card-footer text-danger">{pendingPayment.length} фактури със статус чакащи плащане.</div>);
        }
    };

    const generateStatus = (paid) => {
        return !paid ? <p className="text-danger">Чакаща плащане</p> : <p className="text-success">Платена</p>;
    };

    const generateDebtsTable = () => {
        if (!obligationsForClient) {
            return `Задълженията за ${clientInfo.name} се събират...`;
        }

        obligationsForClient.sort((x, y) => new Date(y.date) - new Date(x.date));

        return (<div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Дата</th>
                        <th scope="col">№ фактура</th>
                        <th scope="col">№ стокова</th>
                        <th scope="col">Партиден №</th>
                        <th scope="col">Сума</th>
                        <th scope="col">Описание стока</th>
                        <th scope="col">Статус</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {obligationsForClient.map(x => {
                        const obligationStatus = generateStatus(x.paid);
                        const buttonClassName = !x.paid ? 'btn btn-outline-success' : 'btn btn-secondary';
                        const buttonText = !x.paid ? 'Маркирай като платена' : 'Платена';

                        return (<tr key={x.id}>
                            <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                            <td>{x.invoiceNumber}</td>
                            <td>{x.stockNumber}</td>
                            <td>{x.batchNumber}</td>
                            <td>{x.ammount.toLocaleString()}</td>
                            <td>{x.description}</td>
                            <td>{obligationStatus}</td>
                            <td><button className={buttonClassName} onClick={() => x.paid ? unMarkObligation({ id: x.id, invoiceNumber: x.invoiceNumber, ammount: x.ammount }) : setObligationAsPaid({ id: x.id, invoiceNumber: x.invoiceNumber, ammount: x.ammount })}>{buttonText}</button></td>
                            <td><button className="btn btn-outline-danger" onClick={() => deleteObligation({ id: x.id, invoiceNumber: x.invoiceNumber, ammount: x.ammount })}>Изтриване</button></td>
                        </tr>);
                    })}
                </tbody>
            </table>
        </div>);
    };

    const clientCard = getClientCard();
    const debtsTable = generateDebtsTable();

    return (<div className="row">
        {clientCard}
        {debtsTable}
        <Modal onAfterOpen={onOpenModal} isOpen={addIncomeModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нов приход">
            <h2 className="text-center mb-3">Създай нов приход</h2>
            <form className="needs-validation" id="addIncomeForm" noValidate name="addIncome">
                <div className="row">
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Входна сума:
                            <input className="form-control" id="initialAmmountInput" name="initialAmmount" type="number" step=".01" onChange={handleChange} required />
                        </label>
                        <div id="InitialAmmountFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">Клиент:
                            <input className="form-control" id="clientNameInput" name="clientName" type="text" />
                        </label>
                        <div id="ClientNameFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-4">
                        <label className="form-label">№ документ:
                            <input className="form-control" id="documentNumberInput" name="documentNumber" type="text" />
                        </label>
                        <div id="DocumentNumberFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label className="form-label">Фолио:
                            <input className="form-control" id="foilInput" name="foil" type="number" step=".01" />
                        </label>
                        <div id="FoilFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label className="form-label">Машини:
                            <input className="form-control" id="machinesInput" name="machines" type="number" step=".01" />
                        </label>
                        <div id="MachinesFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label className="form-label">Наем:
                            <input className="form-control" id="rentInput" name="rent" type="number" step=".01" />
                        </label>
                        <div id="RentFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label className="form-label">Разходи:
                            <input className="form-control" id="expensesInput" name="expenses" type="number" step=".01" />
                        </label>
                        <div id="ExpensesFeedback" className="invalid-feedback hiddenElement"></div>
                    </div>
                </div>
                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={addIncome}>Създай</button>
            </form>
        </Modal>
    </div>);
};

export default ClientInfo;