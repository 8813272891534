import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CrudOperations } from '../util/CrudOperations';
import { Util } from '../util/Util';

const ChangePassword = () => {
    const navigate = useNavigate();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        checkIfLoggedIn();
    }, []);

    const validatePassword = (password) => {
        if (!password || password.length <= 6) {
            return false;
        }

        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;

        return password.match(regex) ? true : false;
    };

    const onChangePassword = async (event) => {
        event.preventDefault();

        const form = document.getElementById('changePasswordForm');
        if (!form.checkValidity()) {
            form.classList.add('was-validated');

            return;
        }

        const currentPassword = document.getElementById('currentPassword');
        const passwordFeedback = document.getElementById('CurrentPasswordFeedback');
        const newPassword = document.getElementById('newPassword');
        const newPasswordFeedback = document.getElementById('NewPasswordFeedback');
        const confirmNewPassword = document.getElementById('confirmNewPassword');
        const confirmNewPasswordFeedback = document.getElementById('ConfirmNewPasswordFeedback');

        const isValidPassword = validatePassword(newPassword.value || null);

        if (!isValidPassword) {
            newPasswordFeedback.setHTML('Паролата трябва да съдържа поне 6 символа, включващи главни и малки букви, цифри и знаци.');
            newPasswordFeedback.classList.remove('hiddenElement');
            newPasswordFeedback.classList.add('visibleElement');
            newPasswordFeedback.previousElementSibling.value = '';
        } else {
            //newPasswordFeedback.setHTML('');
            //newPasswordFeedback.classList.add('hiddenElement');
            //newPasswordFeedback.classList.remove('visibleElement');
        }

        if (newPassword.value !== confirmNewPassword.value) {
            confirmNewPasswordFeedback.setHTML('Паролите не съвпадат.');
            confirmNewPasswordFeedback.classList.remove('hiddenElement');
            confirmNewPasswordFeedback.classList.add('visibleElement');
            confirmNewPasswordFeedback.previousElementSibling.value = '';
        } else {
            //confirmNewPasswordFeedback.setHTML('');
            //confirmNewPasswordFeedback.classList.add('hiddenElement');
            //confirmNewPasswordFeedback.classList.remove('visibleElement');
        }

        if (!currentPassword.value || currentPassword.value.length === 0) {
            passwordFeedback.setHTML('Сегашната парола трябва да бъде попълнена.');
            passwordFeedback.classList.remove('hiddenElement');
            passwordFeedback.classList.add('visibleElement');
        }

        const changePasswordModel = {
            currentPassword: currentPassword.value,
            newPassword: newPassword.value,
            confirmNewPassword: confirmNewPassword.value
        };

        const result = await CrudOperations.changePassword(changePasswordModel);
        debugger;
        if (result.success) {
            Util.showSuccess(`Паролата беше сменена успешно!`);

            navigate('/');
        } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            form.classList.add('was-validated');

            keys.forEach(key => {
                const error = errors[key][0];
                const feedbackId = `${key.trim()}Feedback`;
                const feedbackElement = document.getElementById(feedbackId);

                if (feedbackElement) {
                    feedbackElement.setHTML(error);
                    feedbackElement.classList.remove('hiddenElement');
                    feedbackElement.classList.add('visibleElement');
                    feedbackElement.previousElementSibling.value = '';

                    Util.showError(error);
                }
            });

            Util.showError(`Неуспешен смяна на паролата.`);
            return;
        }
    };

    return (
        <div>
            <h1 className="text-center mb-5">Смяна на парола</h1>
            <form className="needs-validation" id="changePasswordForm" noValidate>
                <div className="form-group mb-2">
                    <label htmlFor="password">Сегашна парола</label>
                    <input type="password" className="form-control" id="currentPassword" placeholder="Въведи сегашната парола" required />
                    <div id="CurrentPasswordFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="password">Нова парола</label>
                    <input type="password" className="form-control" id="newPassword" placeholder="Въведи новата парола" required />
                    <div id="NewPasswordFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="password">Потвърди новата парола</label>
                    <input type="password" className="form-control" id="confirmNewPassword" placeholder="Потвърди новата парола" required />
                    <div id="ConfirmNewPasswordFeedback" className="invalid-feedback hiddenElement"></div>
                </div>
                <button type="submit" className="btn btn-outline-success" onClick={onChangePassword}>Смени парола</button>
            </form>
        </div>
    );
}

export default ChangePassword;