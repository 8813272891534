import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const AdministrationDropdown = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret size="md" className="navigationDropdown">Администрация</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => navigate('/change-password')}>Смяна на парола</DropdownItem>
                <DropdownItem onClick={() => navigate('/users')}>Потребители</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default AdministrationDropdown;