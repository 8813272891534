import React, { useEffect, useState } from 'react';
import { CrudOperations } from '../util/CrudOperations';
import { Util } from '../util/Util';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Store from './Store';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%'
    }
};

const Products = () => {
    const navigate = useNavigate();

    // Data
    const [materials, setMaterials] = useState(null);
    const [types, setTypes] = useState(null);
    const [thicknesses, setThicknesses] = useState(null);
    const [widths, setWidths] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    //  Modals
    const [addMaterialModalIsOpen, setAddMaterialModalIsOpen] = useState(false);
    const [addTypeModalIsOpen, setAddTypeModalIsOpen] = useState(false);
    const [addThicknessModalIsOpen, setAddThicknessModalIsOpen] = useState(false);
    const [addWidthModalIsOpen, setAddWidthModalIsOpen] = useState(false);
    const [editMaterialModalIsOpen, setEditMaterialModalIsOpen] = useState(false);
    const [editTypeModalIsOpen, setEditTypeModalIsOpen] = useState(false);
    const [editThicknessModalIsOpen, setEditThicknessModalIsOpen] = useState(false);
    const [editWidthModalIsOpen, setEditWidthModalIsOpen] = useState(false);
    // Selection
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedThickness, setSelectedThickness] = useState(null);
    const [selectedWidth, setSelectedWidth] = useState(null);

    // Edit
    const [itemForEdit, setItemForEdit] = useState(null);
    const [editedMaterialName, setEditedMaterialName] = useState(null);
    const [editedTypeName, setEditedTypeName] = useState(null);
    const [editedThickness, setEditedThickness] = useState(null);
    const [editedWidth, setEditedWidth] = useState(null);
    const [editedNotes, setEditedNotes] = useState(null);

    const getMaterialsRequest = async () => await CrudOperations.getMaterials();
    const getTypesRequest = async () => await CrudOperations.getTypes();
    const getWidthsRequest = async () => await CrudOperations.getWidths();
    const getThicknessesRequest = async () => await CrudOperations.getThicknesses();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getMaterialsData = async () => setMaterials(await getMaterialsRequest());
        const getTypesData = async () => setTypes(await getTypesRequest());
        const getWidthsData = async () => setWidths(await getWidthsRequest());
        const getThicknessesData = async () => setThicknesses(await getThicknessesRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getMaterialsData();
        getTypesData();
        getWidthsData();
        getThicknessesData();
        checkIfLoggedIn();
    }, []);

    const onDelete = async (id, type) => {
        let isMaterial = type === 'Материал';
        let isType = type === 'Тип';
        let isWidth = type === 'Широчина';
        let isThickness = type === 'Дебелина';

        let itemForDeletion;

        if (isMaterial) {
            itemForDeletion = materials.filter(x => x.id === id)[0];
        } else if (isType) {
            itemForDeletion = types.filter(x => x.id === id)[0];
        } else if (isWidth) {
            itemForDeletion = widths.filter(x => x.id === id)[0];
        } else if (isThickness) {
            itemForDeletion = thicknesses.filter(x => x.id === id)[0];
        }

        const materialName = itemForDeletion.name ? itemForDeletion.name : itemForDeletion.width ? `${itemForDeletion.width} мм` : `${itemForDeletion.thickness} микрона`;

        try {
            confirmAlert({
                title: 'Потвърждение за изтриване',
                message: `Сигурен ли си, че искаш да изтриеш ${materialName}?`,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        const isSelected = (selectedMaterial && id.toLowerCase() === selectedMaterial.toLowerCase()
                            || selectedType && id.toLowerCase() === selectedType.toLowerCase()
                            || selectedThickness && id.toLowerCase() === selectedThickness.toLowerCase()
                            || selectedWidth && id.toLowerCase() === selectedWidth.toLowerCase());

                        if (isSelected) {
                            onCleanSelection(id);
                        }

                        let error = '';
                        if (isMaterial) {
                            error = await CrudOperations.deleteMaterial(id);
                        } else if (isType) {
                            error = await CrudOperations.deleteType(id);
                        } else if (isWidth) {
                            error = await CrudOperations.deleteWidth(id);
                        } else if (isThickness) {
                            error = await CrudOperations.deleteThickness(id);
                        }

                        if (error) {
                            Util.showError(`Не е открит запис за ${materialName}. Error message: ${error}`);
                        } else {
                            if (isMaterial) {

                                setMaterials(await getMaterialsRequest());
                            } else if (isType) {
                                setTypes(await getTypesRequest());
                            } else if (isWidth) {
                                setWidths(await getWidthsRequest());
                            } else if (isThickness) {
                                setThicknesses(await getThicknessesRequest());
                            }
                        }
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    };

    const onEdit = async (id, type) => {
        const isMaterial = type === 'Материал';
        const isType = type === 'Тип';
        const isWidth = type === 'Широчина';
        const isThickness = type === 'Дебелина';

        let itemForEditing;

        if (isMaterial) {
            itemForEditing = materials.filter(x => x.id === id)[0];

            setEditMaterialModalIsOpen(true);
            setItemForEdit(itemForEditing);
            setEditedMaterialName(itemForEditing.name);
            setEditedNotes(itemForEditing.notes);
        } else if (isType) {
            itemForEditing = types.filter(x => x.id === id)[0];

            setEditTypeModalIsOpen(true);
            setItemForEdit(itemForEditing);
            setEditedTypeName(itemForEditing.name);
            setEditedNotes(itemForEditing.notes);
        } else if (isWidth) {
            itemForEditing = widths.filter(x => x.id === id)[0];

            setEditWidthModalIsOpen(true);
            setItemForEdit(itemForEditing);
            setEditedWidth(itemForEditing.width);
            setEditedNotes(itemForEditing.notes);
        } else if (isThickness) {
            itemForEditing = thicknesses.filter(x => x.id === id)[0];

            setEditThicknessModalIsOpen(true);
            setItemForEdit(itemForEditing);
            setEditedThickness(itemForEditing.thickness);
            setEditedNotes(itemForEditing.notes);
        }
    };

    const cleanSelectedLine = (selectLineId) => {
        const selectedLine = document.getElementById(`line_${selectLineId}`);
        if (selectedLine) {
            selectedLine.style.backgroundColor = '';
        }
    };

    const onSelectType = async (id) => {
        // Find what exactly is selected and mark it.
        const isMaterial = materials.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];
        const isType = types.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];
        const isThickness = thicknesses.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];
        const isWidth = widths.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];

        if (isMaterial) {
            if (selectedMaterial) {
                cleanSelectedLine(selectedMaterial);
                onCleanSelection(id);
            }

            setSelectedMaterial(id);
        } else if (isType) {
            if (selectedType) {
                cleanSelectedLine(selectedType);
                onCleanSelection(id);
            }

            setSelectedType(id);
        } else if (isThickness) {
            if (selectedThickness) {
                cleanSelectedLine(selectedThickness);
                onCleanSelection(id);
            }

            setSelectedThickness(id);
        } else if (isWidth) {
            if (selectedWidth) {
                cleanSelectedLine(selectedWidth);
                onCleanSelection(id);
            }

            setSelectedWidth(id);
        }

        const selectedLine = document.getElementById(`line_${id}`);
        if (selectedLine) {
            selectedLine.style.backgroundColor = 'lightGray';
        }

        const button = document.getElementById(id);
        button.innerHTML = 'Избрано'
        button.disabled = true;

        return;
    };

    const onCleanSelection = (id) => {
        const isIdForMaterial = materials.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];
        const isIdForType = types.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];
        const isIdForWidth = widths.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];
        const isIdForThickness = thicknesses.filter(x => x.id.toLowerCase() === id.toLowerCase())[0];

        cleanSelectedLine(id);

        if (isIdForMaterial) {
            const button = document.getElementById(selectedMaterial);
            button.innerHTML = 'Избери';
            button.disabled = false;

            setSelectedMaterial(null);
            setSelectedType(null);
            setSelectedThickness(null);
            setSelectedWidth(null);
        } else if (isIdForType) {
            const button = document.getElementById(selectedType);
            button.innerHTML = 'Избери';
            button.disabled = false;

            setSelectedType(null);
            setSelectedThickness(null);
            setSelectedWidth(null);
        } else if (isIdForThickness) {
            const button = document.getElementById(selectedThickness);
            button.innerHTML = 'Избери';
            button.disabled = false;

            setSelectedThickness(null);
            setSelectedWidth(null);
        } else if (isIdForWidth) {
            const button = document.getElementById(selectedWidth);
            button.innerHTML = 'Избери';
            button.disabled = false;

            setSelectedWidth(null);
        }
    };

    const doClean = (type) => {
        if (type === 'Материал') {
            if (selectedMaterial) {
                doClean('Тип');
                onCleanSelection(selectedMaterial);
            }
        } else if (type === 'Тип') {
            if (selectedType) {
                doClean('Дебелина');
                onCleanSelection(selectedType);
            }
        } else if (type === 'Дебелина') {
            if (selectedThickness) {
                doClean('Широчина');
                onCleanSelection(selectedThickness);
            }
        } else if (type === 'Широчина') {
            if (selectedWidth) {
                onCleanSelection(selectedWidth);
            }
        }
    };

    const openModal = (event) => {
        // "Добави материал", "Добави тип", "Добави широчина", "Добави дебелина"
        const choice = event.currentTarget.innerHTML;

        if (choice === 'Добави тип') {
            setAddTypeModalIsOpen(true);
        } else if (choice === 'Добави широчина') {
            setAddWidthModalIsOpen(true);
        } else if (choice === 'Добави дебелина') {
            setAddThicknessModalIsOpen(true);
        } else {
            setAddMaterialModalIsOpen(true);
        }
    };

    const closeModal = (event) => {
        // If !event is called manually. Close whatever is open.
        if (!event) {
            setAddMaterialModalIsOpen(false);
            setAddTypeModalIsOpen(false);
            setAddWidthModalIsOpen(false);
            setAddThicknessModalIsOpen(false);

            return;
        }

        if (!event.currentTarget.nextElementSibling) {
            return;
        }

        event.preventDefault();
        // Four options - 'addType', 'addWidth', 'addThickness', 'addMaterial'
        const modalType = event.currentTarget.parentElement.name;

        if (modalType === 'addTypeForm') {
            setAddTypeModalIsOpen(false);
        } else if (modalType === 'addWidthForm') {
            setAddWidthModalIsOpen(false);
        } else if (modalType === 'addThicknessForm') {
            setAddThicknessModalIsOpen(false);
        } else {
            setAddMaterialModalIsOpen(false);
        }
    };

    const closeEditModal = (event) => {
        // If !event is called manually. Close whatever is open.
        if (!event) {
            setEditMaterialModalIsOpen(false);
            setEditTypeModalIsOpen(false);
            setEditWidthModalIsOpen(false);
            setEditThicknessModalIsOpen(false);

            return;
        }

        if (!event.currentTarget.nextElementSibling) {
            return;
        }

        event.preventDefault();
        // Four options - 'editType', 'editWidth', 'editThickness', 'editMaterial'
        const modalType = event.currentTarget.parentElement.name;

        if (modalType === 'editTypeForm') {
            setEditTypeModalIsOpen(false);
        } else if (modalType === 'editWidthForm') {
            setEditWidthModalIsOpen(false);
        } else if (modalType === 'editThicknessForm') {
            setEditThicknessModalIsOpen(false);
        } else {
            setEditMaterialModalIsOpen(false);
        }
    };

    const onCreateNew = async (event) => {
        event.preventDefault();

        let populatedValue = '';
        const choice = event.target.parentElement.name;
        const item = {};

        if (choice === 'addMaterialForm') {
            const form = document.getElementById('addMaterialForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            const materialNameInput = document.getElementById('materialNameInput');
            populatedValue = materialNameInput.value;

            const existing = materials.find(x => x.name === populatedValue);
            if (existing) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуващ материал с името ${populatedValue}`);

                return;
            }
        } else if (choice === 'addTypeForm') {
            const form = document.getElementById('addTypeForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            const typeNameInput = document.getElementById('typeNameInput');
            populatedValue = typeNameInput.value;
            item.materialId = selectedMaterial;

            const existing = types.find(x => x.name === populatedValue && x.materialId.toLowerCase() === selectedMaterial.toLowerCase());
            if (existing) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуващ тип с името ${populatedValue} за този материал.`);

                return;
            }
        } else if (choice === 'addThicknessForm') {
            const form = document.getElementById('addThicknessForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            const thicknessInput = document.getElementById('thicknessInput');
            populatedValue = thicknessInput.value;
            item.materialId = selectedMaterial;
            item.typeId = selectedType;

            const existing = thicknesses.find(x => x.thickness === +populatedValue && x.materialId.toLowerCase() === selectedMaterial.toLowerCase() && x.typeId.toLowerCase() === selectedType.toLowerCase());
            if (existing) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуваща дебелина ${populatedValue} микрона за материал от този тип.`);

                return;
            }
        } else if (choice === 'addWidthForm') {
            const form = document.getElementById('addWidthForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            const widthInput = document.getElementById('widthInput');
            populatedValue = widthInput.value;
            item.materialId = selectedMaterial;
            item.typeId = selectedType;
            item.thicknessId = selectedThickness;

            const existing = widths.find(x => x.width === +populatedValue && x.materialId.toLowerCase() === selectedMaterial.toLowerCase() && x.typeId.toLowerCase() === selectedType.toLowerCase() && x.thicknessId.toLowerCase() === selectedThickness.toLowerCase());
            if (existing) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуваща широчина ${populatedValue} милиметра за материал от този тип с тази дебелина.`);

                return;
            }
        } else {
            return;
        }

        if (!populatedValue || populatedValue.length === 0) {
            Util.showError('Името не може да бъде празна стойност.');

            return;
        }

        const notesInput = document.getElementById('notesInput');
        const notesValue = notesInput.value;
        item.notes = notesValue;

        if (choice === 'addMaterialForm') {
            item.name = populatedValue;

            await CrudOperations.createNewMaterial(item);
            setMaterials(await getMaterialsRequest());
            setAddMaterialModalIsOpen(false);

        } else if (choice === 'addTypeForm') {
            item.name = populatedValue;

            await CrudOperations.createNewType(item);
            setTypes(await getTypesRequest());
            setAddTypeModalIsOpen(false);
        } else if (choice === 'addThicknessForm') {
            item.thickness = populatedValue

            await CrudOperations.createNewThickness(item);
            setThicknesses(await getThicknessesRequest());
            setAddThicknessModalIsOpen(false);
        } else if (choice === 'addWidthForm') {
            item.width = populatedValue;

            await CrudOperations.createNewWidth(item);
            setWidths(await getWidthsRequest());
            setAddWidthModalIsOpen(false);
        } else {
            alert('a de...');
        }
    };

    const onEditItem = async (event) => {
        event.preventDefault();

        let populatedValue = '';
        const choice = event.target.parentElement.name;
        const item = {};
        item.id = itemForEdit.id;

        if (choice === 'editMaterialForm') {
            const form = document.getElementById('editMaterialForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            populatedValue = editedMaterialName;

            const existing = materials.find(x => x.name === populatedValue);
            if (existing && item.id.toLowerCase() !== existing.id.toLowerCase()) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуващ материал с името ${populatedValue}`);

                return;
            }
        } else if (choice === 'editTypeForm') {
            const form = document.getElementById('editTypeForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            populatedValue = editedTypeName;
            item.materialId = selectedMaterial;

            const existing = types.find(x => x.name === populatedValue && x.materialId.toLowerCase() === selectedMaterial.toLowerCase());
            if (existing && item.id.toLowerCase() !== existing.id.toLowerCase()) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуващ тип с името ${populatedValue} за този материал.`);

                return;
            }
        } else if (choice === 'editThicknessForm') {
            const form = document.getElementById('editThicknessForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            populatedValue = editedThickness;
            item.materialId = selectedMaterial;
            item.typeId = selectedType;

            const existing = thicknesses.find(x => x.thickness === +populatedValue && x.materialId.toLowerCase() === selectedMaterial.toLowerCase() && x.typeId.toLowerCase() === selectedType.toLowerCase());
            if (existing && item.id.toLowerCase() !== existing.id.toLowerCase()) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуваща дебелина ${populatedValue} микрона за материал от този тип.`);

                return;
            }
        } else if (choice === 'editWidthForm') {
            const form = document.getElementById('editWidthForm');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');

                return;
            }

            populatedValue = editedWidth;
            item.materialId = selectedMaterial;
            item.typeId = selectedType;
            item.thicknessId = selectedThickness;

            const existing = widths.find(x => x.width === +populatedValue && x.materialId.toLowerCase() === selectedMaterial.toLowerCase() && x.typeId.toLowerCase() === selectedType.toLowerCase() && x.thicknessId.toLowerCase() === selectedThickness.toLowerCase());
            if (existing && item.id.toLowerCase() !== existing.id.toLowerCase()) {
                Util.showError(`Записът не може да бъде създаден. Вече има съществуваща широчина ${populatedValue} милиметра за материал от този тип с тази дебелина.`);

                return;
            }
        } else {
            return;
        }

        if (!populatedValue || populatedValue.length === 0) {
            Util.showError('Името не може да бъде празна стойност.');

            return;
        }

        item.notes = editedNotes;

        if (choice === 'editMaterialForm') {
            item.name = populatedValue;

            await CrudOperations.editMaterial(item);
            setMaterials(await getMaterialsRequest());
            setEditMaterialModalIsOpen(false);

        } else if (choice === 'editTypeForm') {
            item.name = populatedValue;

            await CrudOperations.editType(item);
            setTypes(await getTypesRequest());
            setEditTypeModalIsOpen(false);
        } else if (choice === 'editThicknessForm') {
            item.thickness = populatedValue;

            await CrudOperations.editThickness(item);
            setThicknesses(await getThicknessesRequest());
            setEditThicknessModalIsOpen(false);
        } else if (choice === 'editWidthForm') {
            item.width = populatedValue;

            await CrudOperations.editWidth(item);
            setWidths(await getWidthsRequest());
            setEditWidthModalIsOpen(false);
        }
    };

    const generateRows = (data, type) => {
        if (!data) {
            return 'Генериране на материалите...';
        }

        if (data.length === 0) {
            return 'Все още няма добавени записи';
        }

        const isMaterial = type === 'Материал';
        const isType = type === 'Тип';
        const isWidth = type === 'Широчина';
        const isThickness = type === 'Дебелина';

        if (isType) {
            data = data.filter(x => x.materialId.toLowerCase() === selectedMaterial.toLowerCase());
        } else if (isThickness) {
            data = data.filter(x => x.typeId.toLowerCase() === selectedType.toLowerCase());
        } else if (isWidth) {
            data = data.filter(x => x.thicknessId.toLowerCase() === selectedThickness.toLowerCase());
        }

        let sorted = [];
        if (isMaterial || isType) {
            sorted = data.sort((a, b) => a.name.localeCompare(b.name));
        } else if (isThickness) {
            sorted = data.sort((a, b) => a.thickness - b.thickness);
        } else if (isWidth) {
            sorted = data.sort((a, b) => a.width - b.width);
        }

        return (<table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">{isMaterial || isType ? `Име` : isWidth ? `Широчина (мм)` : `Дебелина (микрона)`}</th>
                    <th scope="col">Бележки</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {sorted.map(x => {
                    return (<tr key={x.id} id={`line_${x.id}`}>
                        <th scope="row">{isMaterial || isType ? x.name : isWidth ? `${x.width} мм` : `${x.thickness} микрона`}</th>
                        <td>{x.notes}</td>
                        <td><button className="btn btn-sm btn-outline-primary" id={x.id} onClick={() => onSelectType(x.id)} type="submit">Избери</button></td>
                        <td><button className="btn btn-sm btn-outline-secondary" onClick={() => onEdit(x.id, type)} type="submit">Редактирай</button></td>
                        <Modal isOpen={editMaterialModalIsOpen} onRequestClose={closeEditModal} style={customStyles} contentLabel="Редактирай материал">
                            <h2 className="text-center mb-3">Редактирай материал</h2>
                            <form className="needs-validation" id="editMaterialForm" noValidate name="editMaterialForm">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Име:
                                            <input className="form-control" id="editMaterialNameInput" name="materialName" type="text" onChange={ev => setEditedMaterialName(ev.target.value)} value={editedMaterialName} />
                                        </label>
                                        <div id="MaterialNameFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Бележки:
                                            <textarea className="form-control" onChange={ev => setEditedNotes(ev.target.value)} value={editedNotes}></textarea>
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeEditModal}>Затвори</button>
                                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onEditItem}>Редактирай</button>
                            </form>
                        </Modal>
                        <Modal isOpen={editTypeModalIsOpen} onRequestClose={closeEditModal} style={customStyles} contentLabel="Редактирай тип">
                            <h2 className="text-center mb-3">Редактирай тип</h2>
                            <form className="needs-validation" id="editTypeForm" noValidate name="editTypeForm">
                                <div className="row">
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label">Име:
                                            <input className="form-control" id="editTypeNameInput" name="typeName" type="text" onChange={ev => setEditedTypeName(ev.target.value)} value={editedTypeName} />
                                        </label>
                                        <div id="TypeNameFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label">За материал:
                                            <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={getForMaterial()} disabled />
                                        </label>
                                        <div id="TypeNameFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label">Бележки:
                                            <textarea className="form-control" onChange={ev => setEditedNotes(ev.target.value)} value={editedNotes}></textarea>
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeEditModal}>Затвори</button>
                                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onEditItem}>Редактирай</button>
                            </form>
                        </Modal>
                        <Modal isOpen={editThicknessModalIsOpen} onRequestClose={closeEditModal} style={customStyles} contentLabel="Редактирай дебелина">
                            <h2 className="text-center mb-3">Редактирай дебелина</h2>
                            <form className="needs-validation" id="editThicknessForm" noValidate name="editThicknessForm">
                                <div className="row">
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Дебелина (микрона):
                                            <input className="form-control" id="editThicknessInput" name="thickness" type="number" step=".01" onChange={ev => setEditedThickness(ev.target.value)} value={editedThickness} required />
                                        </label>
                                        <div id="ThicknessFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За материал:
                                            <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={getForMaterial()} disabled />
                                        </label>
                                        <div id="ForMaterialFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За тип:
                                            <input className="form-control" id="forTypeInput" name="forType" type="text" value={getForType()} disabled />
                                        </label>
                                        <div id="ForTypeFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Бележки:
                                            <textarea className="form-control" onChange={ev => setEditedNotes(ev.target.value)} value={editedNotes}></textarea>
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeEditModal}>Затвори</button>
                                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onEditItem}>Редактирай</button>
                            </form>
                        </Modal>
                        <Modal isOpen={editWidthModalIsOpen} onRequestClose={closeEditModal} style={customStyles} contentLabel="Редактирай широчина">
                            <h2 className="text-center mb-3">Редактирай широчина</h2>
                            <form className="needs-validation" id="editWidthForm" noValidate name="editWidthForm">
                                <div className="row">
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label">Широчина (мм):
                                            <input className="form-control" id="editWidthInput" name="width" type="number" step=".01" onChange={ev => setEditedWidth(ev.target.value)} value={editedWidth} required />
                                        </label>
                                        <div id="WidthFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label">За материал:
                                            <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={getForMaterial()} disabled />
                                        </label>
                                        <div id="ForMaterialFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-4">
                                        <label className="form-label">За тип:
                                            <input className="form-control" id="forTypeInput" name="forType" type="text" value={getForType()} disabled />
                                        </label>
                                        <div id="ForTypeFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">За дебелина:
                                            <input className="form-control" id="forThicknessInput" name="forThickness" type="text" value={getForThickness()} disabled />
                                        </label>
                                        <div id="ForWidthFeedback" className="invalid-feedback hiddenElement"></div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label className="form-label">Бележки:
                                            <textarea className="form-control" onChange={ev => setEditedNotes(ev.target.value)} value={editedNotes}></textarea>
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeEditModal}>Затвори</button>
                                <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onEditItem}>Редактирай</button>
                            </form>
                        </Modal>
                        <td><button className="btn btn-sm btn-outline-danger" onClick={() => onDelete(x.id, type)} type="submit">Изтрий</button></td>
                    </tr>);
                })}
            </tbody>
        </table>);
    };

    const getForMaterial = () => {
        if (!materials || !selectedMaterial) {
            return;
        }

        const match = materials.filter(x => x.id.toLowerCase() === selectedMaterial.toLowerCase())[0];

        return match.name;
    };

    const getForType = () => {
        if (!types || !selectedType) {
            return;
        }

        const match = types.filter(x => x.id.toLowerCase() === selectedType.toLowerCase())[0];

        return match.name;
    };

    const getForThickness = () => {
        if (!thicknesses || !selectedThickness) {
            return;
        }

        const match = thicknesses.filter(x => x.id.toLowerCase() === selectedThickness.toLowerCase())[0];

        if (!match) {
            return;
        }

        return `${match.thickness} микрона`;
    };

    const getForWidth = () => {
        if (!widths || !selectedWidth) {
            return;
        }

        const match = widths.filter(x => x.id.toLowerCase() === selectedWidth.toLowerCase())[0];

        if (!match) {
            return;
        }

        return `${match.width} мм`;
    };

    const generateTitle = (isMainTitle = true) => {
        let title = '';

        if (!selectedMaterial) {
            return;
        }

        const material = materials.filter(x => x.id.toLowerCase() === selectedMaterial.toLowerCase())[0];
        title = `${material.name} ->`;

        if (selectedType) {
            const type = types.filter(x => x.id.toLowerCase() === selectedType.toLowerCase())[0];

            if (type) {
                title += ` ${type.name} ->`;
            }
        }

        if (selectedThickness) {
            const thickness = thicknesses.filter(x => x.id.toLowerCase() === selectedThickness.toLowerCase())[0];

            if (thickness) {
                title += ` ${thickness.thickness} микрона ->`;
            }
        }

        if (selectedWidth) {
            const width = widths.filter(x => x.id.toLowerCase() === selectedWidth.toLowerCase())[0];

            if (width) {
                title += ` ${width.width} мм`;
            }
        }

        if (isMainTitle) {
            return (<h1 className="text-center mb-5">{title}</h1>);
        } else {
            return (<h4 className="mb-5">{title}:</h4>);
        }
    };

    const materialsRows = generateRows(materials, 'Материал');
    const typesRows = selectedMaterial ? generateRows(types, 'Тип') : '';
    const thicknessesRows = selectedType ? generateRows(thicknesses, 'Дебелина') : '';
    const widthsRows = selectedThickness ? generateRows(widths, 'Широчина') : '';
    const generatedTitle = generateTitle();

    return (
        <div>
            <h1 className="text-center mb-3">Материали склад</h1>
            {generatedTitle}
            <div className="row mb-5">
                <div className="col-md-6">
                    <h4 className="mb-4">1. Всички налични материали</h4>
                    {materialsRows}
                    <hr />
                    <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={() => doClean('Материал')}>Изчисти селекцията</button>
                    <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={openModal}>Добави материал</button>
                    <Modal isOpen={addMaterialModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нов материал">
                        <h2 className="text-center mb-3">Създай нов материал</h2>
                        <form className="needs-validation" id="addMaterialForm" noValidate name="addMaterialForm">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Име:
                                        <input className="form-control" id="materialNameInput" name="materialName" type="text" />
                                    </label>
                                    <div id="MaterialNameFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Бележки:
                                        <textarea className="form-control" id="notesInput"></textarea>
                                    </label>
                                </div>
                            </div>
                            <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                            <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onCreateNew}>Създай</button>
                        </form>
                    </Modal>
                </div>
                <div className="col-md-6">
                    <h4 className="mb-4">2. Налични типове спрямо селекцията</h4>
                    {typesRows}
                    <hr />
                    <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={() => doClean('Тип')}>Изчисти селекцията</button>
                    <button disabled={selectedMaterial ? "" : "disabled"} style={{ float: 'right' }} className="btn btn-outline-primary" onClick={openModal}>Добави тип</button>
                    <Modal isOpen={addTypeModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нов тип">
                        <h2 className="text-center mb-3">Създай нов тип</h2>
                        <form className="needs-validation" id="addTypeForm" noValidate name="addTypeForm">
                            <div className="row">
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Име:
                                        <input className="form-control" id="typeNameInput" name="typeName" type="text" />
                                    </label>
                                    <div id="TypeNameFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">За материал:
                                        <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={getForMaterial()} disabled />
                                    </label>
                                    <div id="TypeNameFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Бележки:
                                        <textarea className="form-control" id="notesInput"></textarea>
                                    </label>
                                </div>
                            </div>
                            <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                            <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onCreateNew}>Създай</button>
                        </form>
                    </Modal>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-6">
                    <h4 className="mb-4">3. Налични дебелини спрямо селекцията</h4>
                    {thicknessesRows}
                    <hr />
                    <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={() => doClean('Дебелина')}>Изчисти селекцията</button>
                    <button disabled={selectedType ? "" : "disabled"} style={{ float: 'right' }} className="btn btn-outline-primary" onClick={openModal}>Добави дебелина</button>
                    <Modal isOpen={addThicknessModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нова дебелина">
                        <h2 className="text-center mb-3">Създай нова дебелина</h2>
                        <form className="needs-validation" id="addThicknessForm" noValidate name="addThicknessForm">
                            <div className="row">
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Дебелина (микрона):
                                        <input className="form-control" id="thicknessInput" name="thickness" type="number" step=".01" required />
                                    </label>
                                    <div id="ThicknessFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">За материал:
                                        <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={getForMaterial()} disabled />
                                    </label>
                                    <div id="ForMaterialFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">За тип:
                                        <input className="form-control" id="forTypeInput" name="forType" type="text" value={getForType()} disabled />
                                    </label>
                                    <div id="ForTypeFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Бележки:
                                        <textarea className="form-control" id="notesInput"></textarea>
                                    </label>
                                </div>
                            </div>
                            <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                            <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onCreateNew}>Създай</button>
                        </form>
                    </Modal>
                </div>
                <div className="col-md-6">
                    <h4 className="mb-4">4. Налични широчини спрямо селекцията</h4>
                    {widthsRows}
                    <hr />
                    <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={() => doClean('Широчина')}>Изчисти селекцията</button>
                    <button disabled={selectedThickness ? "" : "disabled"} style={{ float: 'right' }} className="btn btn-outline-primary" onClick={openModal}>Добави широчина</button>
                    <Modal isOpen={addWidthModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нова широчина">
                        <h2 className="text-center mb-3">Създай нова широчина</h2>
                        <form className="needs-validation" id="addWidthForm" noValidate name="addWidthForm">
                            <div className="row">
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Широчина (мм):
                                        <input className="form-control" id="widthInput" name="width" type="number" step=".01" required />
                                    </label>
                                    <div id="WidthFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">За материал:
                                        <input className="form-control" id="forMaterialInput" name="forMaterial" type="text" value={getForMaterial()} disabled />
                                    </label>
                                    <div id="ForMaterialFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">За тип:
                                        <input className="form-control" id="forTypeInput" name="forType" type="text" value={getForType()} disabled />
                                    </label>
                                    <div id="ForTypeFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">За дебелина:
                                        <input className="form-control" id="forThicknessInput" name="forThickness" type="text" value={getForThickness()} disabled />
                                    </label>
                                    <div id="ForWidthFeedback" className="invalid-feedback hiddenElement"></div>
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Бележки:
                                        <textarea className="form-control" id="notesInput"></textarea>
                                    </label>
                                </div>
                            </div>
                            <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                            <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={onCreateNew}>Създай</button>
                        </form>
                    </Modal>
                </div>
            </div>
            <hr />
            <Store title={generateTitle(false)} materialId={selectedMaterial} forMaterial={getForMaterial()}
                typeId={selectedType} forType={getForType()}
                thicknessId={selectedThickness} forThickness={getForThickness()}
                widthId={selectedWidth} forWidth={getForWidth()} />
        </div>
    );
};

export default Products;