import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const ReportsDropdown = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret size="md" className="navigationDropdown">Справки</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => navigate('/clients-report')}>Справки за клиенти</DropdownItem>
                <DropdownItem onClick={() => navigate('/incomes-report')}>Справки за приходи</DropdownItem>
                <DropdownItem onClick={() => navigate('/products-report')}>Справки за продукти</DropdownItem>
                <DropdownItem onClick={() => navigate('/invoices-report')}>Справки за фактури</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default ReportsDropdown;