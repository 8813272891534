import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { Util } from '../util/Util';
import { CrudOperations } from '../util/CrudOperations';
import DropdownMenuDetails from './DropdownMenu';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%'
    }
};

Modal.setAppElement(document.getElementById('root'));

const IncomeCalculator = () => {
    const navigate = useNavigate();

    const [incomes, setIncomes] = useState(null);
    const [expenses, setExpenses] = useState(null);
    const [total, setTotal] = useState(null);
    const [addIncomeModalIsOpen, setAddIncomeModalIsOpen] = useState(false);
    const [addExpenseModalIsOpen, setAddExpenseModalIsOpen] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);

    const getAllIncomesRequest = async () => await CrudOperations.getAllIncomes();
    const getAllExpensesRequest = async () => await CrudOperations.getAllExpenses();
    const getTotalRequest = async () => await CrudOperations.getTotal();
    const isLoggedInRequest = async () => await CrudOperations.isAuthenticated();

    useEffect(() => {
        const getIncomesData = async () => setIncomes(await getAllIncomesRequest());
        const getExpensesData = async () => setExpenses(await getAllExpensesRequest());
        const getTotalData = async () => setTotal(await getTotalRequest());
        const checkIfLoggedIn = async () => {
            const result = await isLoggedInRequest();

            if (!result) {
                navigate('/login');

                return;
            }

            setIsUserLoggedIn(result);
        };

        getIncomesData();
        getExpensesData();
        getTotalData();
        checkIfLoggedIn();
    }, []);

    const addExpense = async (event) => {
        event.preventDefault();

        const foilInput = document.getElementById('foilInputExpense');
        const machinesInput = document.getElementById('machinesInputExpense');
        const rentInput = document.getElementById('rentInputExpense');
        const expensesInput = document.getElementById('expensesInputExpense');
        const descriptionInput = document.getElementById('descriptionInputExpense');

        const foilValue = parseFloat(foilInput.value) || 0;
        const machinesValue = parseFloat(machinesInput.value) || 0;
        const rentValue = parseFloat(rentInput.value) || 0;
        const expensesValue = parseFloat(expensesInput.value) || 0;
        const descriptionValue = descriptionInput.value;

        if (foilValue === 0 && machinesValue === 0 && rentValue === 0 && expensesValue === 0) {
            Util.showError(`Грешен запис. Не може да бъде записан разход от 0.`);

            return;
        }

        if (foilValue < 0 || machinesValue < 0 || rentValue < 0 || expenses < 0) {
            Util.showError(`Грешен запис. Не може да бъде записан разход с отрицателна стойност.`);

            return;
        }

        const expense = {
            foil: foilValue,
            machines: machinesValue,
            rent: rentValue,
            expenses: expensesValue,
            description: descriptionValue
        };

        const success = await CrudOperations.createNewExpense(expense);

        if (success) {
            const expensesResult = await getAllExpensesRequest();
            const newTotal = await getTotalRequest();

            closeModal();
            setExpenses(expensesResult);
            setTotal(newTotal);
        } else {
            Util.showError(`Неуспешно записване на разход.`);
        }
    };

    const addIncome = async (event) => {
        event.preventDefault();

        const initialAmmountInput = document.getElementById('initialAmmountInput');
        const foilInput = document.getElementById('foilInput');
        const machinesInput = document.getElementById('machinesInput');
        const rentInput = document.getElementById('rentInput');
        const expensesInput = document.getElementById('expensesInput');
        const clientNameInput = document.getElementById('clientNameInput');
        const documentNumberInput = document.getElementById('documentNumberInput');

        const initialAmmountValue = parseFloat(initialAmmountInput.value);
        const foilValue = parseFloat(foilInput.value) || 0;
        const machinesValue = parseFloat(machinesInput.value) || 0;
        const rentValue = parseFloat(rentInput.value) || 0;
        const expensesValue = parseFloat(expensesInput.value) || 0;

        if (initialAmmountValue !== foilValue + machinesValue + rentValue + expensesValue) {
            Util.showError(`Грешен запис. Прихода е ${initialAmmountValue}, а общата сума на останалите стойности е ${foilValue + machinesValue + rentValue + expensesValue}. Сумата трябва да бъде равна.`);

            return;
        }

        const form = document.getElementById('addIncomeForm');
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');

            return;
        }

        const income = {
            initialAmmount: initialAmmountValue,
            foil: foilValue,
            machines: machinesValue,
            rent: rentValue,
            expenses: expensesValue,
            clientName: clientNameInput.value,
            documentNumber: documentNumberInput.value
        };

        if (!initialAmmountValue || initialAmmountValue <= 0) {
            Util.showError('Приход не може да бъде по-малък или равен на 0.');

            return;
        }

        const result = await CrudOperations.createNewIncome(income);

        if (result.success) {
            const incomesResult = await getAllIncomesRequest();
            const newTotal = await getTotalRequest();

            closeModal();
            setIncomes(incomesResult);
            setTotal(newTotal);

        } else {
            event.stopPropagation();
            // In theory, we should never be here...
            const errors = result.errors;
            const keys = Object.keys(errors);
            form.classList.add('was-validated');

            keys.forEach(key => {
                const error = errors[key][0];
                const feedbackId = `${key.trim()}Feedback`;
                const feedbackElement = document.getElementById(feedbackId);

                if (feedbackElement) {
                    feedbackElement.setHTML(error);
                    feedbackElement.classList.remove('hiddenElement');
                    feedbackElement.classList.add('visibleElement');
                    feedbackElement.previousElementSibling.value = '';

                    Util.showError(error);
                }
            });

            Util.showError(`Неуспешно записване на приход от: ${initialAmmountValue}`);
        }
    };

    const deleteRecord = async (id, type) => {
        try {
            confirmAlert({
                title: 'Потвърждение за изтриване',
                message: `Сигурен ли си, че искаш да изтриеш този запис?`,
                buttons: [{
                    label: 'Да',
                    onClick: async () => {
                        const error = type === 'income' ? await CrudOperations.deleteIncome(id) : await CrudOperations.deleteExpense(id);

                        if (error) {
                            Util.showError(`Не е открит запис за този запис. Error message: ${error}`);
                        } else {
                            const incomesResult = await getAllIncomesRequest();
                            const expensesResult = await getAllExpensesRequest();
                            const newTotal = await getTotalRequest();

                            setIncomes(incomesResult);
                            setExpenses(expensesResult);
                            setTotal(newTotal);
                        }
                    }
                }, {
                    label: 'Не',
                    onClick: () => {
                        return;
                    }
                }],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        } catch (e) {
            Util.showError(e);
        }
    };

    const openModal = (event) => {
        // "Добави разход", "Добави приход"
        const choice = event.currentTarget.innerHTML;

        if (choice === 'Добави приход') {
            setAddIncomeModalIsOpen(true);
        } else {
            setAddExpenseModalIsOpen(true);
        }
    }

    const closeModal = (event) => {
        // If !event is called manually. Close whatever is open.
        if (!event) {
            setAddIncomeModalIsOpen(false);
            setAddExpenseModalIsOpen(false);

            return;
        }

        if (!event.currentTarget.nextElementSibling) {
            return;
        }

        event.preventDefault();
        // Two options - 'addIncome' and 'addExpense'
        const modalType = event.currentTarget.parentElement.name;

        if (modalType === 'addIncome') {
            setAddIncomeModalIsOpen(false);
        } else {
            setAddExpenseModalIsOpen(false);
        }
    };

    const handleChange = (event) => {
        const initialAmmountValue = parseFloat(event.target.value);
        const foilInput = document.getElementById('foilInput');
        const machinesInput = document.getElementById('machinesInput');
        const rentInput = document.getElementById('rentInput');
        const expensesInput = document.getElementById('expensesInput');

        const calculatedFoil = 0.5 * initialAmmountValue;
        const calculatedMachines = 0.14 * initialAmmountValue;
        const calculatedRent = 0.05 * initialAmmountValue;
        const calculatedExpenses = initialAmmountValue - (calculatedFoil + calculatedMachines + calculatedRent);

        foilInput.value = calculatedFoil.toFixed(2);
        machinesInput.value = calculatedMachines.toFixed(2);
        rentInput.value = calculatedRent.toFixed(2);
        expensesInput.value = calculatedExpenses.toFixed(2);
    };

    const generateButtons = () => {
        return (<div>
            <span className="input-group-btn">
                <button className="btn m-2 btn-outline-success" onClick={openModal}>Добави приход</button>
                <Modal isOpen={addIncomeModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави нов приход">
                    <h2 className="text-center mb-3">Създай нов приход</h2>
                    <form className="needs-validation" id="addIncomeForm" noValidate name="addIncome">
                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Входна сума:
                                    <input className="form-control" id="initialAmmountInput" name="initialAmmount" type="number" step=".01" onChange={handleChange} required />
                                </label>
                                <div id="InitialAmmountFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Клиент:
                                    <input className="form-control" id="clientNameInput" name="clientName" type="text" />
                                </label>
                                <div id="ClientNameFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">№ документ:
                                    <input className="form-control" id="documentNumberInput" name="documentNumber" type="text" />
                                </label>
                                <div id="DocumentNumberFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Фолио:
                                    <input className="form-control" id="foilInput" name="foil" type="number" step=".01" />
                                </label>
                                <div id="FoilFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Машини:
                                    <input className="form-control" id="machinesInput" name="machines" type="number" step=".01" />
                                </label>
                                <div id="MachinesFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Наем:
                                    <input className="form-control" id="rentInput" name="rent" type="number" step=".01" />
                                </label>
                                <div id="RentFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                            <div className="mb-3 col-md-3">
                                <label className="form-label">Разходи:
                                    <input className="form-control" id="expensesInput" name="expenses" type="number" step=".01" />
                                </label>
                                <div id="ExpensesFeedback" className="invalid-feedback hiddenElement"></div>
                            </div>
                        </div>
                        <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                        <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={addIncome}>Създай</button>
                    </form>
                </Modal>
                <button className="btn btn-outline-danger" onClick={openModal}>Добави разход</button>
                <Modal isOpen={addExpenseModalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Добави разход">
                    <h2 className="text-center mb-3">Добави разход</h2>
                    <form name="addExpense">
                        <div className="row">
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Фолио:
                                    <input className="form-control" id="foilInputExpense" name="foilExpense" type="number" step=".01" />
                                </label>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Машини:
                                    <input className="form-control" id="machinesInputExpense" name="machinesExpense" type="number" step=".01" />
                                </label>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Наем:
                                    <input className="form-control" id="rentInputExpense" name="rentExpense" type="number" step=".01" />
                                </label>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label className="form-label">Разходи:
                                    <input className="form-control" id="expensesInputExpense" name="expensesExpense" type="number" step=".01" />
                                </label>
                            </div>
                            <div className="mb-3 col-md-4 offset-md-4">
                                <label className="form-label">Описание:
                                    <textarea className="form-control" id="descriptionInputExpense"></textarea>
                                </label>
                            </div>
                        </div>
                        <button className="btn btn-outline-secondary" style={{ float: 'left' }} onClick={closeModal}>Затвори</button>
                        <button className="btn btn-outline-primary" style={{ float: 'right' }} onClick={addExpense}>Създай</button>
                    </form>
                </Modal>
            </span>
            <span className="input-group-btn m-2" style={{ float: 'right' }}>
                <DropdownMenuDetails></DropdownMenuDetails>
            </span>
        </div>);
    };

    const generateTotalInformation = () => {
        if (!total) {
            return 'Изчисляване на тотал...';
        }

        return (<div className="card">
            <div className="card-header">Тотал</div>
            <div className="card-body">
                <blockquote className="blockquote mb-0">
                    <p>Входна сума: {total.initialAmmount.toLocaleString()}, фолио: {total.foil.toLocaleString()}, машини: {total.machines.toLocaleString()}, наем: {total.rent.toLocaleString()}, разходи: {total.expenses.toLocaleString()}.</p>
                    <footer className="blockquote-footer">Последно променян на <cite title="Source Title">{new Date(total.lastUpdated).toLocaleString("bg-BG")}</cite></footer>
                </blockquote>
            </div>
        </div>);
    };

    const generateIncomesAndExpensesTable = (data) => {
        return (<div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Дата</th>
                        <th scope="col">Клиент</th>
                        <th scope="col">№ документ</th>
                        <th scope="col">Тип</th>
                        <th scope="col">Входна сума</th>
                        <th scope="col">Фолио</th>
                        <th scope="col">Машини</th>
                        <th scope="col">Наем</th>
                        <th scope="col">Разходи</th>
                        <th scope="col">Описание</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(x => {
                        if (x.initialAmmount) {
                            return (<tr key={x.id}>
                                <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                                <td>{x.clientName}</td>
                                <td>{x.documentNumber}</td>
                                <td><p className="text-success">Приход</p></td>
                                <td>{x.initialAmmount.toLocaleString()}</td>
                                <td>{x.foil.toLocaleString()}</td>
                                <td>{x.machines.toLocaleString()}</td>
                                <td>{x.rent.toLocaleString()}</td>
                                <td>{x.expenses.toLocaleString()}</td>
                                <td>-</td>
                                <td><button className="btn btn-outline-danger" onClick={() => deleteRecord(x.id, 'income')}>Изтриване</button></td>
                            </tr>);
                        } else {
                            return (<tr key={x.id}>
                                <th scope="row">{new Date(x.date).toLocaleString("bg-BG")}</th>
                                <td>-</td>
                                <td>-</td>
                                <td><p className="text-danger">Разход</p></td>
                                <td>-</td>
                                <td>{x.foil.toLocaleString() === '0' ? '0' : '-' + x.foil.toLocaleString()}</td>
                                <td>{x.machines.toLocaleString() === '0' ? '0' : '-' + x.machines.toLocaleString()}</td>
                                <td>{x.rent.toLocaleString() === '0' ? '0' : '-' + x.rent.toLocaleString()}</td>
                                <td>{x.expenses.toLocaleString() === '0' ? '0' : '-' + x.expenses.toLocaleString()}</td>
                                <td>{x.description}</td>
                                <td><button className="btn btn-outline-danger" onClick={() => deleteRecord(x.id, 'expense')}>Изтриване</button></td>
                            </tr>);
                        }
                    })}
                </tbody>
            </table>
        </div>);
    };

    const PaginatedItems = (itemsPerPage) => {
        if (!incomes || !expenses) {
            return 'Генериране на таблица с приходи и разходи...';
        }

        const incomesAndExpenses = incomes.concat(expenses);
        incomesAndExpenses.sort((x, y) => new Date(y.date) - new Date(x.date));

        const endOffset = itemOffset + itemsPerPage;
        const currentItems = incomesAndExpenses.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(incomesAndExpenses.length / itemsPerPage);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % incomesAndExpenses.length;

            setItemOffset(newOffset);
        };

        const generatedTable = generateIncomesAndExpensesTable(currentItems);

        return (<div>
            {generatedTable}
            <ReactPaginate breakClassName={'page-item'} breakLinkClassName={'page-link'} containerClassName={'pagination'} pageClassName={'page-item'} pageLinkClassName={'page-link'}
                previousClassName={'page-item'} previousLinkClassName={'page-link'} nextClassName={'page-item'} nextLinkClassName={'page-link'} activeClassName={'active'}
                breakLabel="..." nextLabel="Следваща" onPageChange={handlePageClick} pageRangeDisplayed={5} pageCount={pageCount} previousLabel="Предишна" renderOnZeroPageCount={null} />
        </div>);
    };

    const totalInformation = generateTotalInformation();
    const buttons = generateButtons();

    return (<div className="container">
        <h1 className="text-center title">Разпределение на приходи</h1>
        {totalInformation}
        <hr />
        {buttons}
        <hr />
        {PaginatedItems(20)}
    </div>);
};

export default IncomeCalculator;