import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const DropdownMenuDetails = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const openDetails = (type) => navigate('/details', { state: { title: type } });

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret size="md">Разбивки</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => openDetails('Входни суми')}>Входни суми</DropdownItem>
                <DropdownItem onClick={() => openDetails('Фолио')}>Фолио</DropdownItem>
                <DropdownItem onClick={() => openDetails('Машини')}>Машини</DropdownItem>
                <DropdownItem onClick={() => openDetails('Наем')}>Наем</DropdownItem>
                <DropdownItem onClick={() => openDetails('Разходи')}>Разходи</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default DropdownMenuDetails;